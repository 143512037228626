import FriendRequest from '@/models/FriendRequest'
import HttpService, { type IHttpService } from '@/services/HttpService'

const controller = 'friendRequests'

class BottleService {
  private readonly _http: IHttpService

  constructor() {
    this._http = new HttpService(controller)
  }

  public async create(recipientId: string): Promise<FriendRequest> {
    const rawFriendRequest = await this._http.post<undefined, FriendRequest>(recipientId, undefined)
    return FriendRequest.clone(rawFriendRequest)
  }

  public async getByUserId(userId: string): Promise<FriendRequest[]> {
    const friendRequestsRaw = await this._http.getWithParams<FriendRequest[]>('', { userId })
    return friendRequestsRaw.map((fr) => FriendRequest.clone(fr))
  }

  public async accept(friendRequestId: string): Promise<FriendRequest> {
    const rawFriendRequest = await this._http.post<undefined, FriendRequest>(
      `${friendRequestId}/accept`,
      undefined
    )
    return FriendRequest.clone(rawFriendRequest)
  }

  public async decline(friendRequestId: string): Promise<FriendRequest> {
    const rawFriendRequest = await this._http.post<undefined, FriendRequest>(
      `${friendRequestId}/decline`,
      undefined
    )
    return FriendRequest.clone(rawFriendRequest)
  }

  public async remove(friendRequestId: string): Promise<void> {
    await this._http.delete(`${friendRequestId}`)
  }
}

export default new BottleService()
