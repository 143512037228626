import { RouterView, createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import LoginView from '@/views/LoginView.vue'
import FeedView from '@/views/FeedView.vue'
import ProfileView from '@/views/ProfileView.vue'
import EditBottleView from '@/views/EditBottleView.vue'
import ViewBottleView from '@/views/ViewBottleView.vue'
import NotificationsView from '@/views/NotificationsView.vue'
import SystemView from '@/views/SystemView.vue'
import BulkImportView from '@/views/BulkImportView.vue'
import FeedItemView from '@/views/FeedItemView.vue'
import SearchView from '@/views/SearchView.vue'
import ViewSpiritView from '@/views/ViewSpiritView.vue'
import EditSpiritView from '@/views/EditSpiritView.vue'
import JoinView from '@/views/JoinView.vue'
import EditProfileView from '@/views/EditProfileView.vue'
import NewRatingView from '@/views/NewRatingView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'feed',
      component: FeedView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: { allowAnonymous: true, redirectIfLoggedIn: true }
    },
    {
      path: '/join/:inviteId?',
      name: 'join',
      component: JoinView,
      meta: { allowAnonymous: true, redirectIfLoggedIn: true }
    },
    {
      path: '/profiles',
      component: RouterView,
      children: [
        {
          path: ':handle',
          name: 'profile',
          component: ProfileView
        },
        {
          path: 'edit',
          name: 'editProfile',
          component: EditProfileView
        }
      ]
    },
    {
      path: '/search',
      name: 'search',
      component: SearchView,
    },
    {
      path: '/spirits',
      component: RouterView,
      children: [
        {
          path: ':id',
          name: 'spirit',
          component: ViewSpiritView,
        },
        {
          path: 'new',
          name: 'newSpirit',
          component: EditSpiritView
        },
        {
          path: ':id/edit',
          name: 'editSpirit',
          component: EditSpiritView
        },
        {
          path: ':spiritId/rate',
          name: 'newSpiritRating',
          component: NewRatingView
        }
      ]
    },
    {
      path: '/bottles',
      component: RouterView,
      children: [
        {
          path: 'new',
          name: 'newBottle',
          component: EditBottleView
        },
        {
          path: ':id',
          name: 'bottle',
          component: ViewBottleView,
        },
        {
          path: ':id/edit',
          name: 'editBottle',
          component: EditBottleView
        },
        {
          path: ':bottleId/rate',
          name: 'newBottleRating',
          component: NewRatingView
        }
      ]
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: NotificationsView
    },
    {
      path: '/posts/:id',
      name: 'post',
      component: FeedItemView
    },
    {
      path: '/system',
      component: RouterView,
      meta: { requiresAdmin: true },
      children: [
        {
          path: '',
          name: 'system',
          component: SystemView
        },
        {
          path: 'bulk-import',
          name: 'bulkImport',
          component: BulkImportView
        }
      ]
    }
  ]
})

router.beforeEach(async (to, _from, next) => {
  const userStore = useUserStore()
  const didInitialLoginCheck = userStore.didInitialLoginCheck

  if (!didInitialLoginCheck) await userStore.doInitialLoginCheck()
  const isLoggedIn = userStore.isLoggedIn
  const isAdmin = userStore.isAdmin

  if (isLoggedIn && to.meta?.redirectIfLoggedIn === true) {
    console.log("LOGGED IN")
    next({ name: 'feed' })
  } else if (!isLoggedIn && to.meta?.allowAnonymous !== true) {
    console.log("NOT LOGGED IN")
    next({ name: 'login', query: { redirect: to.path } })
  } else if (!isAdmin && to.meta?.requiresAdmin === true) {
    next({ name: 'feed' })
  } else next()
})

export default router
