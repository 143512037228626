<script setup lang="ts">
import Notification from '@/models/Notification'
import VUserAvatar from '@/components/VUserAvatar.vue'
import { NotificationType } from '@/enums/NotificationType'
import type FriendRequestAcceptedNotification from '@/models/FriendRequestAcceptedNotification'
import type NewFriendRequestNotification from '@/models/NewFriendRequestNotification'
import type NewLikeNotification from '@/models/NewLikeNotification'
import type NewCommentNotification from '@/models/NewCommentNotification'
import { computed } from 'vue'
import { intervalToDuration, differenceInDays } from 'date-fns'
import { useUserStore } from '@/stores/user'
import VIcon from '@/components/VIcon.vue'

const userStore = useUserStore()
const props = defineProps<{ notification: Notification }>()
const emit = defineEmits(['acceptFriendRequest', 'declineFriendRequest'])

const toRoute = computed(() => {
  const nt = props.notification.notificationType
  if (nt === NotificationType.FriendRequestAccepted || nt === NotificationType.NewFriendRequest)
    return { name: 'profile', params: { handle: triggerUser.value?.handle } }

  if (nt === NotificationType.NewComment)
    return {
      name: 'post',
      params: { id: (props.notification as NewCommentNotification).comment.feedItemId }
    }

  return {
    name: 'post',
    params: { id: (props.notification as NewLikeNotification).like.feedItemId }
  }
})

const triggerUser = computed(() => {
  const nt = props.notification.notificationType
  if (nt === NotificationType.FriendRequestAccepted)
    return (props.notification as FriendRequestAcceptedNotification).friendRequest.recipient!
  if (nt === NotificationType.NewComment)
    return (props.notification as NewCommentNotification).comment.user
  if (nt === NotificationType.NewLike) return (props.notification as NewLikeNotification).like.user
  if (nt === NotificationType.NewFriendRequest)
    return (props.notification as NewFriendRequestNotification).friendRequest.sender!
  return undefined
})

function ago(date: Date) {
  const { years, months, days, hours, minutes } = intervalToDuration({
    start: date,
    end: new Date()
  })

  if (years) return `${years}y ago`
  if (months) return `${Math.round(differenceInDays(new Date(), date) / 7)}w ago`
  if (days) return `${days}d ago`
  if (hours) return `${hours}h ago`
  if (minutes) return `${minutes}m ago`
  return `Now`
}

function acceptFriendRequest(notification: Notification) {
  userStore.acceptFriendRequest(
    (notification as NewFriendRequestNotification).friendRequest.senderId!
  )
  emit('acceptFriendRequest', notification)
}

function declineFriendRequest(notification: Notification) {
  userStore.declineFriendRequest(
    (notification as NewFriendRequestNotification).friendRequest.senderId!
  )
  emit('declineFriendRequest', notification)
}
</script>

<template>
  <div class="notification" :class="{ 'is-new': !notification.seen }">
    <div class="me-3">
      <VUserAvatar :user="triggerUser!"></VUserAvatar>
    </div>
    <div class="mt-n1">
      <router-link
        :to="{ name: 'profile', params: { handle: triggerUser!.handle } }"
        class="text-white fw-bold"
      >
        {{ triggerUser!.handle }}
      </router-link>
      <span
        v-if="notification.notificationType === NotificationType.FriendRequestAccepted"
        class="ms-1 text-stone-300"
        >has accepted your friend request.</span
      >
      <span
        v-if="notification.notificationType === NotificationType.NewLike"
        class="ms-1 text-stone-300"
        >liked <router-link :to="toRoute">your post</router-link>.</span
      >
      <span
        v-if="notification.notificationType === NotificationType.NewComment"
        class="ms-1 text-stone-300"
        >commented on <router-link :to="toRoute">your post</router-link>.</span
      >
      <span
        v-if="notification.notificationType === NotificationType.NewFriendRequest"
        class="ms-1 text-stone-300"
        >sent you a friend request.</span
      >
      <div>
        <span class="d-block small text-stone-400" style="font-size: 11px">
          {{ ago(notification.created) }}
        </span>
      </div>
    </div>
    <div
      class="ps-2 ms-auto text-nowrap"
      v-if="notification.notificationType === NotificationType.NewFriendRequest"
    >
      <button class="btn btn-primary px-2 py-1 ms-2" @click="acceptFriendRequest(notification)">
        Accept
      </button>
      <button
        class="btn btn-secondary px-2 py-1 ms-2"
        @click="declineFriendRequest(notification)"
      >
        <VIcon prefix="br" icon="cross"></VIcon>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.notification {
  display: flex;
  border-bottom: 1px solid map-get($custom-colors, 'stone-1000');
  border-top: 1px solid map-get($custom-colors, 'stone-800');
  padding: 20px;
  border-left: 4px solid transparent;
}

.notification.is-new {
  border-bottom: 1px solid map-get($custom-colors, 'stone-900');
  border-top: 1px solid map-get($custom-colors, 'stone-700');
  background: map-get($custom-colors, 'stone-800');
  border-left: 4px solid map-get($custom-colors, 'amber-500');
}
</style>
