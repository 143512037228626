import HttpService, { type IHttpService } from '@/services/HttpService'
import Bottle from '@/models/Bottle'

const controller = 'bottles'

class BottleService {
  private readonly _http: IHttpService

  constructor() {
    this._http = new HttpService(controller)
  }

  public async create(bottle: Bottle): Promise<Bottle> {
    const bottleRaw = await this._http.post<Bottle, Bottle>('', bottle);
    return Bottle.clone(bottleRaw);
  }

  public async get(id: string): Promise<Bottle> {
    const bottle: Bottle = await this._http.get('', id)
    return Bottle.clone(bottle)
  }

  public async getByUser(userId: string): Promise<Bottle[]> {
    const bottlesRaw: Bottle[] = await this._http.getWithParams('', { userId })
    return bottlesRaw.map((b) => Bottle.clone(b))
  }

  public async update(bottle: Bottle): Promise<Bottle> {
    const bottleRaw = await this._http.put<Bottle, Bottle>('', bottle);
    return Bottle.clone(bottleRaw);
  }

  public async delete(id: string): Promise<void> {
    return await this._http.delete(id)
  }
}

export default new BottleService()
