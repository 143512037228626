<script setup lang="ts">
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import { useProfileStore } from '@/stores/profile'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter, type RouteLocationRaw } from 'vue-router'
import { computed, ref, watch } from 'vue'
import VUserAvatar from '@/components/VUserAvatar.vue'
import VFeed from '@/components/VFeed.vue'
import VCollection from '@/components/VCollection.vue'
import VUserList from '@/components/VUserList.vue'
import VIcon from '@/components/VIcon.vue'
import VRatingCard from '@/components/VRatingCard.vue'
import type Bottle from '@/models/Bottle'
import { FeedItemType } from '@/enums/FeedItemType'
import type NewRatingFeedItem from '@/models/NewRatingFeedItem'

type Tab = 'feed' | 'collection' | 'friends' | 'posts' | 'bottles' | 'ratings'

const route = useRoute()
const router = useRouter()
const profileStore = useProfileStore()
const {
  user,
  collection,
  friends,
  feed,
  isSelf,
  isFriendsWithCurrentUser,
  currentUserNeedsToRespond,
  currentUserAwaitingResponse,
  friendRequestForCurrentUser,
  ratings
} = storeToRefs(profileStore)

const userStore = useUserStore()

const handle = computed(() => route.params.handle as string)
const tab = ref<Tab>('feed')
const hash = computed(() => route.hash?.replace('#', ''))
const title = computed(() => {
  if (user.value) return user.value.handle
  if (isBadUser.value) return ''
  return 'Loading...'
})
const isBadUser = ref(false)
const profileIsLoading = ref(false)
const feedIsLoading = ref(true)

function setTab(_tab: Tab) {
  router.push({ hash: `#${_tab}` })
}

function isValidTab(str: string) {
  return ['feed', 'collection', 'friends', 'posts', 'bottles', 'ratings'].includes(
    str.toLowerCase()
  )
}

async function sendRequest() {
  if (!user.value?.id) return
  await userStore.sendFriendRequest(user.value.id)
}

async function acceptRequest() {
  if (!user.value?.id) return
  await userStore.acceptFriendRequest(user.value.id)
}

async function declineRequest() {
  if (!user.value?.id) return
  await userStore.declineFriendRequest(user.value.id)
}

async function removeRequest() {
  if (!user.value?.id) return
  await userStore.removeFriendRequest(user.value.id)
}

async function deleteBottle(bottle: Bottle) {
  await profileStore.deleteBottle(bottle)
}

function getRouteToPost(ratingId: string) {
  const postId = feed.value?.find(
    (fi) =>
      fi.feedItemType === FeedItemType.NewRating && (fi as NewRatingFeedItem).ratingId === ratingId
  )

  if (!postId) return undefined

  return { name: 'post', params: { id: postId.id } } as RouteLocationRaw
}

watch(
  hash,
  (newHash) => {
    if (isValidTab(newHash)) tab.value = newHash as Tab
    else {
      router.replace({ hash: undefined })
      tab.value = 'feed'
    }
  },
  { immediate: true }
)

watch(handle, async (newHandle, oldHandle) => {
  if (newHandle !== oldHandle && route.name === 'profile') {
    await init()
  }
})

async function logout() {
  await userStore.logout()
  profileStore.reset()
  router.push('/')
}

//ON CREATED
;(async () => {
  await init()
})()

async function init() {
  try {
    if (profileStore.user?.handle && profileStore.user.handle !== handle.value) profileStore.reset()
    profileIsLoading.value = !profileStore.user
    feedIsLoading.value = true
    await profileStore.getFullProfile(handle.value)
  } catch (err) {
    console.error(err)
    isBadUser.value = true
  } finally {
    feedIsLoading.value = false
    profileIsLoading.value = false
  }
}
</script>

<template>
  <TitleBarLayout :title="title" @logout="logout" :show-logout="true">
    <div class="px-0" v-if="user && !profileIsLoading">
      <!-- #region Header -->
      <div class="d-flex align-items-center p-3 bg-stone-850">
        <VUserAvatar :user="user" :size="100" class="ms-3"></VUserAvatar>
        <div class="ms-4">
          <span class="fs-5 d-block fw-bold"> {{ user.firstName }} {{ user.lastName }} </span>
          <span class="d-block text-stone-400 mt-n1">@{{ user.handle }}</span>
          <template v-if="!isSelf">
            <button
              class="btn btn-primary py-1 mt-3"
              v-if="!friendRequestForCurrentUser"
              @click="sendRequest"
            >
              <VIcon prefix="sr" icon="user-add"></VIcon>
              <span class="ms-2 fs-6" style="letter-spacing: 0px">Add Friend</span>
            </button>

            <div v-if="currentUserNeedsToRespond" class="mt-3 d-flex">
              <button class="btn btn-primary py-1 d-block mx-auto" @click="acceptRequest">
                <VIcon prefix="sr" icon="check"></VIcon>
                <span class="ms-1" style="letter-spacing: 0px">Accept</span>
              </button>
              <button
                class="btn btn-outline-stone-300 py-1 d-block mx-auto ms-3"
                @click="declineRequest"
              >
                <span style="letter-spacing: 0px">Decline</span>
              </button>
            </div>

            <button
              class="btn btn-outline-primary py-1 mt-3"
              v-if="isFriendsWithCurrentUser"
              @click="removeRequest"
            >
              <VIcon prefix="sr" icon="remove-user"></VIcon>
              <span class="ms-2 fs-6" style="letter-spacing: 0px">Unfriend</span>
            </button>

            <span class="btn btn-stone-700 py-1 mt-3" v-if="currentUserAwaitingResponse">
              <VIcon prefix="sr" icon="message-arrow-up-right"></VIcon>
              <span class="ms-2 fs-6" style="letter-spacing: 0px">Requested</span>
            </span>
          </template>
          <router-link
            :to="{ name: 'editProfile' }"
            class="btn btn-secondary py-1 mt-3"
            v-if="isSelf"
          >
            <VIcon prefix="sr" icon="user-pen"></VIcon>
            <span class="ms-2 fs-6" style="letter-spacing: 0px">Edit Profile</span>
          </router-link>
        </div>
      </div>
      <!-- #endregion -->

      <!-- #region Tabs -->
      <div class="py-1 bg-stone-850">
        <div class="tab-container">
          <div class="d-flex justify-content-between">
            <div class="tab-link-container">
              <a
                role="button"
                @click="setTab('feed')"
                class="tab-link"
                :class="{ 'is-active': ['feed', 'posts'].includes(tab) }"
              >
                <span class="d-block text-white small">{{ feed?.length ?? 0 }}</span>
                <span class="d-block fw-bold text-uppercase">Posts</span>
              </a>
            </div>
            <div class="tab-link-container text-center">
              <a
                role="button"
                @click="setTab('collection')"
                class="tab-link"
                :class="{ 'is-active': ['bottles', 'collection'].includes(tab) }"
              >
                <span class="d-block text-white small">{{ collection?.length ?? 0 }}</span>
                <span class="d-block fw-bold text-uppercase">Bottles</span>
              </a>
            </div>
            <div class="tab-link-container text-center">
              <a
                role="button"
                @click="setTab('ratings')"
                class="tab-link"
                :class="{ 'is-active': ['ratings'].includes(tab) }"
              >
                <span class="d-block text-white small">{{ ratings?.length ?? 0 }}</span>
                <span class="d-block fw-bold text-uppercase">Ratings</span>
              </a>
            </div>
            <div class="tab-link-container text-end">
              <a
                role="button"
                @click="setTab('friends')"
                class="tab-link"
                :class="{ 'is-active': tab === 'friends' }"
              >
                <span class="d-block text-white small">{{ friends?.length ?? 0 }}</span>
                <span class="d-block fw-bold text-uppercase">Friends</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- #endregion -->

      <div class="p-3">
        <VFeed
          v-if="tab === 'feed'"
          :feed-items="feed ?? []"
          :is-loading="feedIsLoading"
          :prefer-user="user"
        ></VFeed>
        <VCollection
          v-if="tab === 'collection'"
          :bottles="collection"
          :show-menu="true"
          @deleteBottle="deleteBottle"
        >
          <template #empty v-if="isSelf">
            <div class="p-3 text-center">
              <span class="text-stone-300">
                Your collection is empty!
                <br />
                <router-link class="fw-bold" :to="{ name: 'newBottle' }">Click here</router-link> to
                add your first bottle.
              </span>
            </div>
          </template>
        </VCollection>

        <div v-if="tab === 'ratings'">
          <VRatingCard
            v-for="rating in ratings"
            :key="rating.id"
            :rating="rating"
            :post-route="getRouteToPost(rating.id!)"
          ></VRatingCard>
        </div>

        <VUserList v-if="tab === 'friends'" :users="friends"></VUserList>
      </div>
    </div>

    <div class="px-0 text-center pt-5" v-if="profileIsLoading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-if="isBadUser">
      <div class="text-center fs-5 p-4">
        <VIcon
          prefix="br"
          icon="mode-portrait"
          class="text-stone-700"
          style="font-size: 100px"
        ></VIcon>
        <span class="d-block mb-4">Uh oh!<br />It looks like this user doesn't exist.</span>
        <router-link :to="{ name: 'feed' }">&lt; Back to feed</router-link>
      </div>
    </div>
  </TitleBarLayout>
</template>

<style lang="scss" scoped>
@import '@/assets/colors.scss';

.tab-link-container {
  flex: 1 1 0;
}

a.tab-link {
  display: block;
  letter-spacing: 0.5px;
  color: map-get($custom-colors, 'stone-400');
  padding: 10px 0;
  transition: color 0.2s;
  text-align: center;
  transition: all 0.3s;
  font-weight: bold;
}

a.tab-link:hover {
  color: map-get($custom-colors, 'stone-200');
}

a.tab-link.is-active {
  color: map-get($custom-colors, 'stone-0');
  border-color: white;
}

.spinner-border {
  border-color: map-get($custom-colors, 'stone-500');
  border-right-color: transparent;
  height: 150px;
  width: 150px;
  border-width: 10px;
}
</style>
