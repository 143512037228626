<script setup lang="ts">
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import FeedItem from '@/models/FeedItem'
import feedItemService from '@/services/FeedItemService'
import { useRoute } from 'vue-router'
import { ref, computed } from 'vue'
import { FeedItemType } from '@/enums/FeedItemType'
import VNewBottleFeedItem from '@/components/VNewBottleFeedItem.vue'
import NewBottleFeedItem from '@/models/NewBottleFeedItem'
import NewFriendshipFeedItem from '@/models/NewFriendshipFeedItem'
import VNewFriendshipFeedItem from '@/components/VNewFriendshipFeedItem.vue'
import VNewRatingFeedItem from '@/components/VNewRatingFeedItem.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import VComments from '@/components/VComments.vue'
import commentService from '@/services/CommentService'
import Comment from '@/models/Comment'
import VButton from '@/components/VButton.vue'
import VUserAvatar from '@/components/VUserAvatar.vue'
import VIcon from '@/components/VIcon.vue'
import NewRatingFeedItem from '@/models/NewRatingFeedItem'

const { user: currentUser } = storeToRefs(useUserStore())

const id = ref(useRoute().params.id as string | undefined)
const feedItem = ref<FeedItem | undefined>()
const isLoading = ref(false)
const isCommenting = ref(false)
const comment = ref('')
const characterCount = computed(() => comment.value.length)
const maxCharacters = 400

async function addComment(e: Event) {
  e.preventDefault()
  if (comment.value.length && comment.value.length <= 400) {
    isCommenting.value = true
    try {
      const newComment = await commentService.create(
        new Comment(currentUser.value!, feedItem.value!.id, comment.value)
      )
      feedItem.value?.comments.push(newComment)
      comment.value = ''
    } catch {
      //noop
    } finally {
      isCommenting.value = false
    }
  }
}

async function deleteComment(commentId: string) {
  await commentService.delete(commentId)
  const idx = feedItem.value!.comments.findIndex((c) => c.id === commentId)
  if (idx > -1) feedItem.value!.comments.splice(idx, 1)
}

//ON CREATED
;(async () => {
  if (!id.value) return
  isLoading.value = true

  try {
    feedItem.value = await feedItemService.get(id.value)
  } catch {
    //noop
  } finally {
    isLoading.value = false
  }
})()
</script>

<template>
  <TitleBarLayout title="Post" :hide-bottom-nav="true">
    <template #back>
      <a @click="$router.back" class="d-block text-white">
        <VIcon prefix="br" icon="angle-left"></VIcon>
      </a>
    </template>
    <div class="p-0 d-flex flex-column" v-if="feedItem">
      <template v-if="feedItem.feedItemType === FeedItemType.NewBottle">
        <VNewBottleFeedItem
          class="embedded p-3 bg-stone-1000"
          :feed-item="feedItem"
          :bottle="(feedItem as NewBottleFeedItem).bottle"
        ></VNewBottleFeedItem>
      </template>

      <template v-if="feedItem.feedItemType === FeedItemType.NewFriendship">
        <VNewFriendshipFeedItem
          class="embedded p-3 bg-stone-1000"
          :prefer-user="currentUser"
          :feed-item="feedItem"
          :friend-request="(feedItem as NewFriendshipFeedItem).friendRequest"
        ></VNewFriendshipFeedItem>
      </template>

      <template v-if="feedItem.feedItemType === FeedItemType.NewRating">
        <VNewRatingFeedItem
          class="embedded p-3 bg-stone-1000"
          :feed-item="feedItem"
          :rating="(feedItem as NewRatingFeedItem).rating"
          :with-notes="true"
          :inline="true"
        ></VNewRatingFeedItem>
      </template>

      <VComments
        :comments="feedItem.comments"
        class="p-3"
        @delete-comment="deleteComment"
      ></VComments>

      <form class="p-3 border-bottom border-stone-600 bg-stone-800 mt-auto" @submit="addComment">
        <label class="d-block text-uppercase fw-black text-stone-400 mb-1" style="font-size: 11px"
          >Leave a comment</label
        >
        <textarea
          v-model="comment"
          class="bg-stone-900"
          style="min-height: 70px"
          maxlength="400"
          :disabled="isCommenting"
        ></textarea>
        <div class="d-flex align-items-center justify-content-between mt-1">
          <span class="d-block small text-stone-400"
            >{{ characterCount }}/{{ maxCharacters }} characters</span
          >
          <VUserAvatar class="ms-auto me-3" :size="30" :user="currentUser!"></VUserAvatar>
          <VButton
            type="submit"
            class="btn btn-primary py-1"
            :is-loading="isCommenting"
            loading-text="Posting..."
            >Comment</VButton
          >
        </div>
      </form>
    </div>
  </TitleBarLayout>
</template>
