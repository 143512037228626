<script setup lang="ts">
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import VUserAvatar from '@/components/VUserAvatar.vue'
import VInputWrapper from '@/components/VInputWrapper.vue'
import { computed, ref } from 'vue'
import type User from '@/models/User'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import VIcon from '@/components/VIcon.vue'
import { handle } from '@/helpers/ValidationHelpers'
import type EditProfileViewModel from '@/models/EditProfileViewModel'
import userService from '@/services/UserService'
import { useProfileStore } from '@/stores/profile'
import { useToast } from 'vue-toastification'
import router from '@/router'
import VButton from '@/components/VButton.vue'

const toast = useToast()
const userStore = useUserStore()
const profileStore = useProfileStore()
const { user: currentUser } = storeToRefs(userStore)
const user = ref({ ...currentUser.value } as User)
const imageInput = ref<HTMLInputElement | null>(null)
const editingUser = ref({ ...currentUser.value } as User)
const handleIsAvailable = ref(true)
const isCheckingHandleAvailability = ref(false)
const isSaving = ref(false)
const timer = ref()

const form = ref<EditProfileViewModel>({
  handle: user.value.handle,
  imageUrl: user.value.imageUrl
})

function available() {
  return handleIsAvailable.value
}

const rules = computed(() => ({
  handle: { required, handle, available }
}))

const v$ = useVuelidate(rules, form)

async function saveProfile(e: Event) {
  e.preventDefault()
  v$.value.$validate()
  if (v$.value.$error) return

  try {
    isSaving.value = true
    await userService.editProfile(form.value)
    userStore.refresh()
    profileStore.reset()
    toast.success('Profile saved.')
    return router.push({ name: 'profile', params: { handle: form.value.handle } })
  } catch {
    //
  } finally {
    isSaving.value = false
  }
}

function editImage() {
  imageInput.value?.click()
}

function newImage(e: Event) {
  const input = e.target as HTMLInputElement
  if (!input.files) return

  const [file] = input.files
  if (!file) return

  const reader = new FileReader()
  reader.addEventListener(
    'load',
    () => {
      editingUser.value.imageUrl = (reader.result ?? '') as string
      form.value.imageUrl = (reader.result ?? '') as string
    },
    false
  )
  reader.readAsDataURL(file)
}

const handleChanged = computed(() => {
  return currentUser.value?.handle !== form.value.handle
})

const isValidHandle = computed(() => {
  return handleIsAvailable.value && !v$.value.handle.$error
})

function validateHandle() {
  v$.value.$validate()
  debouncedHandleCheck()
}

function debouncedHandleCheck() {
  isCheckingHandleAvailability.value = false
  window.clearTimeout(timer.value)

  if (!form.value.handle) {
    handleIsAvailable.value = true
    return
  }

  if (form.value.handle.toLowerCase() === currentUser.value?.handle.toLowerCase()) {
    handleIsAvailable.value = true
    return
  }

  isCheckingHandleAvailability.value = true
  timer.value = window.setTimeout(checkHandleAvailability, 300)
}

async function checkHandleAvailability() {
  try {
    handleIsAvailable.value = await userService.isHandleAvailable(form.value.handle!)
  } catch {
    //
  } finally {
    isCheckingHandleAvailability.value = false
  }
}

function deleteImage() {
  editingUser.value.imageUrl = undefined
  form.value.imageUrl = undefined
}
</script>

<template>
  <TitleBarLayout title="Edit Profile">
    <template #back>
      <a @click="$router.back" class="d-block text-white">
        <VIcon prefix="br" icon="angle-left"></VIcon>
      </a>
    </template>

    <div class="px-0 h-100">
      <form @submit="saveProfile" class="d-flex flex-column h-100">
        <h4 class="px-3 py-2 bg-stone-800 mb-0">Profile</h4>
        <div class="px-3">
          <div class="row gy-3 py-3">
            <div class="col-12 text-center">
              <div class="dropend">
                <a
                  role="button"
                  class="d-inline-block text-white"
                  data-bs-toggle="dropdown"
                  data-bs-popper-config='{"strategy":"fixed"}'
                  aria-expanded="false"
                >
                  <VUserAvatar :user="editingUser!" :size="120"></VUserAvatar>
                </a>

                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" role="button" @click="editImage">
                      <VIcon prefix="rr" icon="edit" class="me-2"></VIcon>
                      <span>Edit</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item text-danger" role="button" @click="deleteImage">
                      <VIcon prefix="rr" icon="trash-xmark" class="me-2"></VIcon>
                      <span>Delete</span>
                    </a>
                  </li>
                </ul>
              </div>
              <input
                type="file"
                ref="imageInput"
                class="d-none"
                accept="image/png, image/jpeg"
                @change="newImage"
              />
            </div>
            <div class="col-12">
              <label class="form-label" for="handle">Handle</label>
              <VInputWrapper prefix="br" icon="at">
                <input
                  class="form-control"
                  id="handle"
                  v-model="form.handle"
                  :class="{ 'is-invalid': v$.handle.$error }"
                  placeholder="ThePappyVanWinkle"
                  @input="validateHandle"
                />
                <span v-if="isValidHandle && handleChanged" class="error-text text-success"
                  ><VIcon prefix="sr" icon="check-circle" class="me-1"></VIcon>Handle
                  available</span
                >
                <span v-if="!handleIsAvailable" class="error-text"
                  ><VIcon prefix="sr" icon="circle-x" class="me-1"></VIcon>Handle already in
                  use</span
                >
                <span v-if="!isValidHandle && handleIsAvailable" class="error-text"
                  >Invalid Handle</span
                >
              </VInputWrapper>
              <span class="small d-block mt-1 text-stone-500">
                Handles must be 25 characters or less, and can contain only letters, numbers,
                underscores, and periods.
              </span>
            </div>
          </div>
        </div>
        <div class="p-3 mt-auto">
          <VButton
            :is-loading="isSaving || isCheckingHandleAvailability"
            loading-text=""
            class="btn btn-primary w-100"
            type="submit"
            >Save Changes</VButton
          >
        </div>
      </form>
    </div>
  </TitleBarLayout>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.title {
  display: inline-block;
  font-size: 10px;
  font-weight: 1000;
  text-transform: uppercase;
  color: map-get($custom-colors, 'stone-500');
  margin-bottom: -3px;
  margin-right: 5px;
}

.form-label {
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 1000;
  color: map-get($custom-colors, 'stone-300');
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.error-text {
  position: absolute;
  top: -17px;
  right: 0;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--bs-form-invalid-color);
}
</style>
