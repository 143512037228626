<script setup lang="ts">
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import searchService from '@/services/SearchService'
import VIcon from '@/components/VIcon.vue'
import { ref, onMounted, computed } from 'vue'
import type SearchResult from '@/models/SearchResult'
import type Spirit from '@/models/Spirit'
import type UserSummary from '@/models/UserSummary'
import VInputWrapper from '@/components/VInputWrapper.vue'
import VUserAvatar from '@/components/VUserAvatar.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const timer = ref<number | undefined>()
const results = ref<SearchResult[]>([])
const query = ref('')
const isSearching = ref(false)
const waiting = ref(false)
const searchInput = ref<HTMLInputElement | null>(null)
const filteredResults = computed(() => {
  if (query.value[0] === '@') return results.value.filter((r) => r.type === 'user')

  return results.value
})

async function search() {
  waiting.value = false
  isSearching.value = true
  try {
    const queryString = query.value[0] === '@' ? query.value.substring(1) : query.value
    results.value = await searchService.search(queryString)
  } catch {
    // noop
  } finally {
    isSearching.value = false
  }
}

function doQuery() {
  waiting.value = false
  window.clearTimeout(timer.value)

  if (query.value === '') {
    isSearching.value = false
    results.value = []
    return
  }

  waiting.value = true
  timer.value = window.setTimeout(search, 500)
}

function addSpirit(name: string) {
  return router.push({ name: 'newSpirit', query: { name, from: 'addBottle' } })
}

onMounted(() => {
  searchInput.value?.focus()
})
</script>

<template>
  <TitleBarLayout title="Search">
    <template #back>
      <a @click="$router.back" class="d-block text-white">
        <VIcon prefix="br" icon="angle-left"></VIcon>
      </a>
    </template>

    <div class="p-0 d-flex flex-column h-100">
      <VInputWrapper prefix="rr" icon="search" class="fs-5">
        <input
          ref="searchInput"
          class="form-control rounded-0 py-3 fs-5 ps-5"
          @input="doQuery"
          v-model="query"
          placeholder="Search friends or spirits..."
        />
        <span class="search-spinner" v-if="isSearching">
          <span class="spinner-border" role="status"></span>
        </span>
      </VInputWrapper>

      <!-- #region RESULTS -->
      <div v-if="filteredResults.length" class="flex-shrink-1 flex-grow-1 overflow-auto">
        <router-link
          :to="{ name: 'newSpirit', query: { name: query } }"
          role="button"
          class="result"
          @click="addSpirit(query)"
        >
          <div
            style="width: 70px; height: 70px"
            class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
          >
            <VIcon prefix="br" icon="plus-small" style="font-size: 50px"></VIcon>
          </div>
          <div class="spirit-header ms-2">
            <h5 class="my-0 text-stone-300">
              Add <i class="text-white">&quot;{{ query }}&quot;</i> as a new Spirit
            </h5>
          </div>
        </router-link>
        <div class="result-container" v-for="result in filteredResults" :key="result.obj.id">
          <template v-if="result.type === 'spirit'">
            <router-link
              v-for="spirit in [result.obj as Spirit]"
              :key="spirit.id"
              role="button"
              class="result"
              :to="{ name: 'spirit', params: { id: spirit.id } }"
            >
              <div
                style="width: 70px; height: 70px"
                class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
              >
                <div
                  class="img-container w-100 h-100"
                  :style="{ 'background-image': `url(${spirit.imgSrc})` }"
                ></div>
              </div>
              <div class="spirit-header ms-2">
                <span class="d-block fw-bold fs-6">{{ spirit.name }}</span>
                <span class="d-block text-stone-400">{{ spirit.type }}</span>
              </div>
            </router-link>
          </template>

          <template v-if="result.type === 'user'">
            <router-link
              v-for="user in [result.obj as UserSummary]"
              :key="user.id"
              role="button"
              class="result"
              :to="{ name: 'profile', params: { handle: user.handle } }"
            >
              <div
                style="width: 70px; height: 70px"
                class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
              >
                <VUserAvatar :user="user" :size="50"></VUserAvatar>
              </div>

              <div class="ms-2">
                <span class="d-block fs-6 text-white fw-bold">{{ user.handle }}</span>
                <span class="d-block text-stone-400">{{ user.firstName }} {{ user.lastName }}</span>
              </div>
            </router-link>
          </template>
        </div>
      </div>
      <!-- #endregion -->

      <div v-if="query.length === 0" class="text-center text-stone-400 p-5">
        <span class="d-block">Use the input above to search friends and spirits.</span>
      </div>

      <div
        v-if="query.length && !isSearching && filteredResults.length === 0 && !waiting"
        class="text-center text-stone-400 p-5"
      >
        <span class="d-block"
          >Sorry, we couldn't find any results for
          <i class="text-white">&quot;{{ query }}&quot;</i></span
        >

        <div class="mt-4 mx-auto text-stone-0">Can't find the spirit you're looking for?</div>
        <router-link
          :to="{ name: 'newSpirit', query: { name: query } }"
          class="mt-1 w-100 btn btn-outline-primary"
          >Add "{{ query}}" as a new Spirit</router-link
        >
      </div>
    </div>
  </TitleBarLayout>
</template>

<style lang="scss">
@import '@/assets/colors.scss';

.img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100px;
  max-width: 50px;
}

.result {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  background-color: map-get($custom-colors, 'stone-800');
}

.spirit-header {
  flex-grow: 1;
}

.result.header {
  background-repeat: no-repeat;
  background-size: cover, cover, 500px;
  background-position:
    center,
    center,
    0% 65%;
  background-color: map-get($custom-colors, 'stone-300');
  border: 0 !important;
  position: relative;
}

.image-upload-container > label {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.image-upload-container input[type='file'] {
  display: none;
}

.result-container:not(:last-child) {
  border-bottom: 1px solid map-get($custom-colors, 'stone-900');
}

.result-container:not(:first-child) {
  border-top: 1px solid map-get($custom-colors, 'stone-700');
}

.search-spinner {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-45%);
}
</style>
