import type UserSummary from "@/models/UserSummary"

export default class FriendRequest {
  public id?: string
  public requested?: Date
  public responded?: Date
  public isAccepted?: boolean
  public senderId?: string
  public sender?: UserSummary
  public recipientId?: string
  public recipient?: UserSummary

  constructor(
    id?: string,
    requested?: Date,
    responded?: Date,
    isAccepted?: boolean,
    senderId?: string,
    sender?: UserSummary,
    recipientId?: string,
    recipient?: UserSummary
  ) {
    this.id = id
    this.requested = requested
    this.responded = responded
    this.isAccepted = isAccepted
    this.senderId = senderId
    this.sender = sender
    this.recipientId = recipientId
    this.recipient = recipient
  }

  static clone(friendRequest: FriendRequest) {
    return new FriendRequest(
        friendRequest.id,
        friendRequest.requested,
        friendRequest.responded,
        friendRequest.isAccepted,
        friendRequest.senderId,
        friendRequest.sender,
        friendRequest.recipientId,
        friendRequest.recipient
    )
  }
}
