import 'bootstrap'
import '@/assets/theme.scss'
import '@/assets/slider.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from '@/App.vue'
import router from '@/router'

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import Popper from 'vue3-popper';
import '@/assets/popper.css';

const app = createApp(App)

app.component('VPopper', Popper);

app.use(createPinia())
app.use(router)
app.use(Toast)

app.mount('#app')
