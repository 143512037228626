import Spirit from "@/models/Spirit";
import type User from "@/models/User";

export default class Bottle {

  public id?: string;
  public owner: User;
  public spirit: Spirit;
  public abv?: Number;
  public sizeInMl?: Number;
  public added?: Date;
  public ageYears?: Number;
  public ageMonths?: Number;
  public distillationDate?: Date;
  public bottlingDate?: Date;
  public seller?: string;
  public pricePaid?: Number;
  public purchaseDate?: Date;
  public openDate?: Date;
  public finishDate?: Date;
  public pick?: string;
  public notes?: string;
  public remaining?: Number;
  public batch?: string;
  public additionalInformation?: { [k: string]: string };

  constructor(
    owner: User,
    spirit?: Spirit,
    abv?: Number,
    sizeInMl?: Number,
    added?: Date,
    id?: string,
    ageYears?: Number,
    ageMonths?: Number,
    distillationDate?: Date,
    bottlingDate?: Date,
    seller?: string,
    pricePaid?: Number,
    purchaseDate?: Date,
    openDate?: Date,
    finishDate?: Date,
    pick?: string,
    notes?: string,
    remaining?: Number,
    batch?: string,
    additionalInformation?: { [k: string]: string }
  ) {
    this.owner = owner;
    this.spirit = spirit ?? new Spirit(owner);
    this.abv = abv;
    this.sizeInMl = sizeInMl;
    this.added = added;
    this.id = id;
    this.ageYears = ageYears;
    this.ageMonths = ageMonths;
    this.distillationDate = distillationDate;
    this.bottlingDate = bottlingDate;
    this.seller = seller;
    this.pricePaid = pricePaid;
    this.purchaseDate = purchaseDate;
    this.openDate = openDate;
    this.finishDate = finishDate;
    this.pick = pick;
    this.notes = notes;
    this.remaining = remaining;
    this.batch = batch;
    this.additionalInformation = additionalInformation;
  }

  static clone(bottle: Bottle) {
    return new Bottle(
      bottle.owner,
      Spirit.clone(bottle.spirit),
      bottle.abv,
      bottle.sizeInMl,
      bottle.added,
      bottle.id,
      bottle.ageYears,
      bottle.ageMonths,
      bottle.distillationDate,
      bottle.bottlingDate,
      bottle.seller,
      bottle.pricePaid,
      bottle.purchaseDate,
      bottle.openDate,
      bottle.finishDate,
      bottle.pick,
      bottle.notes,
      bottle.remaining,
      bottle.batch,
      bottle.additionalInformation ? { ...bottle.additionalInformation } : undefined
    );
  }
}
