<script setup lang="ts">
import VBottomNavigation from '@/components/VBottomNavigation.vue'
import VIcon from '@/components/VIcon.vue';
import Bottle from '@/models/Bottle';
import { formatDistanceToNow } from 'date-fns'
import { computed } from 'vue';
import VUserAvatar from '@/components/VUserAvatar.vue';

const props = defineProps<{
  bottle?: Bottle
}>()

const backRoute = computed(() => {
  return props.bottle?.owner
    ? { name: 'profile', params: { handle: props.bottle.owner.handle }, hash: '#collection' }
    : {}
})


</script>

<template>
  <div class="d-flex flex-column container-xxl" style="height: 100vh">
    <div class="row shadow" v-if="bottle" style="z-index: 2; position: relative;">
        <div class="bg-stone-1000 p-3 d-flex align-items-center">
        <router-link :to="backRoute" class="d-flex align-items-center text-white">
          <VIcon prefix="br" icon="angle-left"></VIcon>
          <VUserAvatar :user="bottle.owner" class="mx-3"></VUserAvatar>
          <div>
            <span class="d-block fw-bold">{{ bottle.owner.handle }}</span>
          </div>
        </router-link>
        <div class="ms-auto small text-end">
          <span class="d-block text-stone-400 mb-n1">Added</span>
          <span class="d-block">{{ formatDistanceToNow(bottle.added!) }} ago</span>
        </div>
      </div>
    </div>
    <main class="content row">
      <slot></slot>
    </main>
    <div class="row">
      <VBottomNavigation
        v-if="!$route.meta.hideBottomNavigation"
        id="bottom-navigation"
      ></VBottomNavigation>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content {
  flex-grow: 1;
  overflow: auto;
}

header {
  display: flex;
  align-items: center;
}

header > *:first-child {
  flex: 1;
  font-size: 20px;
}

header::after {
  content: '';
  flex: 1;
}
</style>
