<script setup lang="ts">
import SplashLayout from '@/views/layouts/SplashLayout.vue'
import VInputWrapper from '@/components/VInputWrapper.vue'
import VError from '@/components/VError.vue'
import { ref, computed } from 'vue'
import { maxLength, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useUserStore } from '@/stores/user'
import { useRouter, useRoute } from 'vue-router'
import VButton from '@/components/VButton.vue'
import VIcon from '@/components/VIcon.vue'

//#region form
interface LoginViewModel {
  emailOrHandle: string
  password: string
}

const form = ref<LoginViewModel>({
  emailOrHandle: '',
  password: ''
})

const rules = computed(() => ({
  emailOrHandle: { required, maxLength: maxLength(100) },
  password: { required, maxLength: maxLength(100) }
}))

const v$ = useVuelidate<LoginViewModel>(rules, form)
//#endregion

const userStore = useUserStore()
const router = useRouter()
const currentRoute = useRoute()

const isLoggingIn = ref(false)
const errorMessage = ref<string | undefined>()

async function login(e: Event) {
  e.preventDefault()

  try {
    v$.value.$validate()
    if (v$.value.$error) return

    isLoggingIn.value = true
    await userStore.login(form.value.emailOrHandle, form.value.password)

    if (userStore.isLoggedIn) redirect()
    else throw new Error('Invalid login.')
  } catch {
    errorMessage.value =
      "Login failed. Please make sure you've entered your email and password correctly."
    isLoggingIn.value = false
  }
}

function redirect() {
  const url = router.resolve(currentRoute.query.redirect?.toString() || '/')
  router.push(url)
}
</script>

<template>
  <SplashLayout>
    <form @submit="login">
      <div class="row mb-3">
        <div class="col">
          <VError v-if="errorMessage">{{ errorMessage }}</VError>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <VInputWrapper icon="at">
            <input
              type="text"
              v-model="form.emailOrHandle"
              placeholder="Email or Handle"
              maxlength="100"
              class="form-control"
              :class="{ 'is-invalid': v$.emailOrHandle.$error }"
            />
          </VInputWrapper>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <VInputWrapper icon="lock">
            <input
              type="password"
              v-model="form.password"
              placeholder="Password"
              maxlength="100"
              class="form-control"
              :class="{ 'is-invalid': v$.password.$error }"
            />
          </VInputWrapper>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <VButton
            class="btn btn-light w-100"
            :is-loading="isLoggingIn"
            type="submit"
            loading-text="Signing in..."
            >Sign In</VButton
          >
        </div>
      </div>
      <div class="row mt-5">
        <div class="col fs-7 text-center">
          <span class="text-stone-400">Don't have an account?</span>
          <router-link :to="{name: 'join'}" class="ms-2">Create one now.</router-link>
          <!-- <span class="text-stone-400">Casknotes is currently <span class="text-stone-0">invite only</span>.</span> -->
        </div>
      </div>
    </form>
  </SplashLayout>
</template>
