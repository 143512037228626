import HttpService, { type IHttpService } from '@/services/HttpService'
import Rating from '@/models/Rating'
import Spirit from '@/models/Spirit'

const controller = 'ratings'

class RatingService {
  private readonly _http: IHttpService

  constructor() {
    this._http = new HttpService(controller)
  }

  public async get(id: string): Promise<Rating> {
    const rating = await this._http.get<Rating>(id);
    if (rating.spirit) rating.spirit = Spirit.clone(rating.spirit)

    return rating;
  }

  public async getWithFilters(filters: { spiritId?: string, bottleId?: string, userId?: string }): Promise<Rating[]> {
    const ratings = await this._http.getWithParams<Rating[]>('', filters)
    ratings.forEach(r => r.spirit = Spirit.clone(r.spirit!))
    return ratings;
  }

  public add(rating: Rating): Promise<Rating> {
    return this._http.post('', rating)
  }

  public delete(id: string): Promise<void> {
    return this._http.delete(id)
  }
}

export default new RatingService()
