import { SpiritType } from '@/enums/SpiritType'
import type User from '@/models/User'
import type UserSummary from '@/models/UserSummary'

export default class Spirit {
  public id?: string
  public creator: User
  public name?: string
  public type?: SpiritType
  public created?: Date;
  public distiller?: string
  public country?: string
  public region?: string
  public subregion?: string
  public tags?: string[]
  public image?: string
  public abv?: number;
  public abv2?: number;
  public ageInYears?: number;
  public description?: string;
  public isReviewed?: boolean;
  public reviewed?: Date;
  public reviewer?: UserSummary;
  public reviewerId?: string;

  constructor(
    creator: User,
    name?: string,
    type?: SpiritType,
    created?: Date,
    distiller?: string,
    country?: string,
    region?: string,
    subregion?: string,
    tags?: string[],
    image?: string,
    id?: string,
    abv?: number,
    abv2?: number,
    ageInYears?: number,
    description?: string,
    isReviewed?: boolean,
    reviewed?: Date,
    reviewer?: UserSummary,
    reviewerId?: string
  ) {
    this.creator = creator
    this.name = name
    this.type = type
    this.created = created
    this.distiller = distiller
    this.country = country
    this.region = region
    this.subregion = subregion
    this.image = image
    this.tags = tags ?? []
    this.id = id 
    this.abv = abv
    this.abv2 = abv2
    this.ageInYears = ageInYears
    this.description = description
    this.isReviewed = isReviewed
    this.reviewed = reviewed
    this.reviewer = reviewer
    this.reviewerId = reviewerId
  }

  get imgSrc() {
    if (this.image === undefined) return `/img/no-image.png`
    return this.image
  }

  static clone(spirit: Spirit) {
    return new Spirit(
      spirit.creator,
      spirit.name,
      spirit.type,
      spirit.created,
      spirit.distiller,
      spirit.country,
      spirit.region,
      spirit.subregion,
      spirit.tags ? [...spirit.tags] : undefined,
      spirit.image,
      spirit.id,
      spirit.abv,
      spirit.abv2,
      spirit.ageInYears,
      spirit.description,
      spirit.isReviewed,
      spirit.reviewed,
      spirit.reviewer,
      spirit.reviewerId
    )
  }

  static new(user: User) {
    return new Spirit(user);
  }
}
