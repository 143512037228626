<script setup lang="ts">
import Bottle from '@/models/Bottle'
import VIcon from '@/components/VIcon.vue'
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const emit = defineEmits(['delete'])
const toast = useToast()
const { user: currentUser } = storeToRefs(useUserStore())

export interface Props {
  bottle: Bottle
  slim?: boolean
  showMenu?: boolean
  inline?: boolean
  filterByName?: string
}

const props = withDefaults(defineProps<Props>(), {
  slim: false,
  showMenu: false,
  inline: false
})

const editable = computed(() => currentUser.value?.id === props.bottle.owner.id)

function highlightByFilterText(name?: string) {
  if (!name) return ''
  if (!props.filterByName || props.filterByName.trim() === '') return name

  return name.replace(new RegExp(props.filterByName, 'gi'), '<span class="h">$&</span>')
}

function deleteBottle(bottle: Bottle) {
  emit('delete', bottle)
}

async function share() {
  const url = `https://casknotes.com/bottles/${props.bottle.id}`
  const shareData: ShareData = {
    title: 'Casknotes',
    text: props.bottle.spirit.name,
    url
  }

  try {
    await navigator.share(shareData)
  } catch (err) {
    await navigator.clipboard.writeText(`Check out this bottle of ${props.bottle.spirit.name} on Casknotes: \n\n${url}`);
    toast.success("Copied to clipboard!")
  }
}
</script>

<template>
  <div
    class="spirit-card d-block my-2 overflow-hidden bg-stone-800 shadow-sm rounded-2"
    :class="{ small: inline }"
  >
    <div class="d-flex py-2" :class="{ 'border-left-primary': inline }">
      <div
        :style="{ width: inline ? '60px' : '70px' }"
        class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
        :class="{ 'px-1': !inline }"
      >
        <div
          class="img-container w-100 h-100"
          :style="{ 'background-image': `url(${bottle.spirit.imgSrc})` }"
        ></div>
      </div>
      <div class="bottle-info-container flex-grow-1" :class="{ 'px-3 py-2': !inline }">
        <div class="spirit-header d-flex mb-2 align-items-center justify-content-between">
          <h5 class="fw-bolder m-0 p-0" style="flex: 1">
            <router-link :to="{ name: 'bottle', params: { id: bottle.id } }" class="text-primary">
              <span v-html="highlightByFilterText(bottle.spirit.name)"></span>
            </router-link>
          </h5>
          <div class="dropdown" v-if="showMenu">
            <a
              role="button"
              class="d-block text-white"
              data-bs-toggle="dropdown"
              data-bs-popper-config='{"strategy":"fixed"}'
              aria-expanded="false"
            >
              <VIcon
                prefix="br"
                icon="menu-dots-vertical"
                class="d-block fs-5"
                style="transform: translateY(-5px)"
              ></VIcon>
            </a>

            <ul class="dropdown-menu">
              <li v-if="editable">
                <router-link
                  :to="{ name: 'editBottle', params: { id: bottle.id } }"
                  class="dropdown-item"
                >
                  <VIcon prefix="rr" icon="edit" class="me-2"></VIcon>
                  <span>Edit</span>
                </router-link>
              </li>
              <li>
                <a class="dropdown-item" role="button" @click="share">
                  <VIcon prefix="rr" icon="share" class="me-2"></VIcon>
                  <span>Share</span>
                </a>
              </li>
              <li v-if="editable">
                <a class="dropdown-item text-danger" role="button" @click="deleteBottle(bottle)">
                  <VIcon prefix="rr" icon="trash-xmark" class="me-2"></VIcon>
                  <span>Delete</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row gy-2" :class="{ 'text-stone-200': inline }">
          <div :class="slim ? 'col-4' : ['col-lg-2', 'col-sm-4', 'col-6']">
            <span class="title">Type</span>
            <span>{{ bottle.spirit.type }}</span>
          </div>
          <div :class="slim ? 'col-4' : ['col-lg-2', 'col-sm-4', 'col-6']">
            <span class="title">ABV</span>
            <span>{{ bottle.abv }}<small>%</small></span>
            <!-- <span class="proof" v-if="proofRange">{{ proofRange }} proof</span> -->
          </div>
          <div :class="slim ? 'col-4' : ['col-lg-2', 'col-sm-4', 'col-6']">
            <span class="title">Age</span>
            <span :class="{ 'is-empty': !bottle.ageYears }">{{
              !bottle.ageYears ? '' : `${bottle.ageYears} years`
            }}</span>
          </div>
          <div v-if="!props.slim" class="col-lg-2 col-sm-4 col-6">
            <span class="title">Distiller</span>
            <span :class="{ 'is-empty': !bottle.spirit.distiller }">{{
              bottle.spirit.distiller
            }}</span>
          </div>
          <div v-if="!props.slim" class="col-lg-2 col-sm-4 col-6">
            <span class="title">Country</span>
            <span :class="{ 'is-empty': !bottle.spirit.country }">{{ bottle.spirit.country }}</span>
          </div>
          <div v-if="!props.slim" class="col-lg-2 col-sm-4 col-6">
            <span class="title">Region</span>
            <span :class="{ 'is-empty': !bottle.spirit.region }">{{ bottle.spirit.region }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/colors.scss';
.border-left-primary {
  border-left: 5px solid map-get($custom-colors, 'amber-500');
}

.title {
  display: block;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: map-get($custom-colors, 'stone-500');
  margin-bottom: -3px;
}

.spirit-header {
  min-height: 35px;
}

.proof {
  font-size: 9px;
  opacity: 0.5;
  display: block;
  margin-top: -5px;
  text-transform: uppercase;
  font-weight: bold;
}

.img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100px;
  max-width: 50px;
}

.is-empty::after {
  content: 'N/A';
  color: map-get($custom-colors, 'stone-600');
}

@media only screen and (max-width: 575px) {
  .spirit-header {
    min-height: 0;
  }

  .spirit-header > h5 {
    font-size: 16px;
  }
}
</style>

<style lang="scss">
@import '@/assets/colors.scss';
.h {
  color: map-get($custom-colors, 'amber-300') !important;
  // background-color: map-get($custom-colors, 'primary') !important;
}
</style>
