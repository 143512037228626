import HttpService, { type IHttpService } from '@/services/HttpService';
import Spirit from '@/models/Spirit';

const controller = 'spirits';

class SpiritService {
  private readonly _http: IHttpService;

  constructor() {
    this._http = new HttpService(controller);
  }

  public async get(spiritId: string): Promise<Spirit> {
    const spiritRaw = await this._http.get<Spirit>(spiritId);
    return Spirit.clone(spiritRaw);
  }

  public async create(spirit: Spirit): Promise<Spirit> {
    const spiritRaw = await this._http.post<Spirit, Spirit>('', spirit);
    return Spirit.clone(spiritRaw)
  }

  public async update(spirit: Spirit): Promise<Spirit> {
    const spiritRaw = await this._http.put<Spirit, Spirit>('', spirit);
    return Spirit.clone(spiritRaw)
  }

  public async search(query: string): Promise<Spirit[]> {
    const spirits: Spirit[] = await this._http.getWithParams('search', { query })
    return spirits.map(s => Spirit.clone(s));
  }
}

export default new SpiritService();