<script setup lang="ts">
import DefaultLayout from '@/views/layouts/DefaultLayout.vue'
import { ref } from 'vue'
import feedItemService from '@/services/FeedItemService'
import type FeedItem from '@/models/FeedItem'
import VFeed from '@/components/VFeed.vue'
import VIcon from '@/components/VIcon.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

const { user: currentUser } = storeToRefs(useUserStore())
const feedItems = ref<FeedItem[] | undefined>()
const isLoading = ref(true)

//ON CREATED
;(async () => {
  try {
    feedItems.value = await feedItemService.getAllForCurrentUser()
  } catch {
    //noop
  } finally {
    isLoading.value = false
  }
})()
</script>

<template>
  <DefaultLayout>
    <div>
      <h3 class="fw-black" v-if="feedItems?.length">Feed</h3>
      <VFeed :feed-items="feedItems ?? []" v-if="!isLoading && feedItems?.length"></VFeed>
      <div class="px-0 text-center pt-5" v-if="isLoading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="mt-4 text-stone-400">Loading your feed...</div>
      </div>
      <div class="p-5 text-center" v-if="!isLoading && !feedItems?.length">
        <h1 class="text-uppercase text-stone-300 my-0">Welcome to <span class="fw-black text-stone-0">Casknotes</span></h1>

        <div class="my-5 fs-5 fs-6">
          This is your <b>Feed</b> page.<br>You'll see all your friends' updates here.
        </div>

        <div class="my-5 fs-5 fs-6">
          Looking for friends? Or some bottles to add to your collection?
          <router-link :to=" {name: 'search'}" class="btn fw-black btn-outline-primary mt-2 d-block">
            <VIcon prefix="br" icon="search" class="me-3 ms-n3"></VIcon>
            <span>Search</span>
          </router-link>
        </div>

        <div class="my-5 fs-5 fs-6">
          Or, head over to your profile:
          <router-link :to="{ name: 'profile', params: { handle: currentUser!.handle }}" class="btn fw-black btn-outline-primary mt-2 d-block">
            <VIcon prefix="br" icon="user" class="me-3 ms-n3"></VIcon>
            <span>Profile</span>
          </router-link>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.spinner-border {
  border-color: map-get($custom-colors, 'stone-500');
  border-right-color: transparent;
  height: 150px;
  width: 150px;
  border-width: 10px;
}
</style>