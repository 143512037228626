<script setup lang="ts">
import SplashLayout from '@/views/layouts/SplashLayout.vue'
import VInputWrapper from '@/components/VInputWrapper.vue'
import VError from '@/components/VError.vue'
import { ref, computed } from 'vue'
import { email, maxLength, required, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useRouter, useRoute } from 'vue-router'
import VButton from '@/components/VButton.vue'
import VIcon from '@/components/VIcon.vue'
import userService from '@/services/UserService'
import type CreateAccountViewModel from '@/models/CreateAccountViewModel'
import { useToast } from 'vue-toastification'
import { handle, password } from '@/helpers/ValidationHelpers'
import { useUserStore } from '@/stores/user'

const INVITE_ONLY = false
const toast = useToast()

//#region form
const form = ref<CreateAccountViewModel>({
  firstName: '',
  lastName: '',
  handle: '',
  email: '',
  password: '',
  confirmPassword: ''
})

const rules = computed(() => ({
  firstName: { required, maxLength: maxLength(25) },
  lastName: { required, maxLength: maxLength(25) },
  handle: { required, handle },
  email: { required, email, maxLength: maxLength(100) },
  password: { required, password },
  confirmPassword: { required, sameAs: sameAs(computed(() => form.value.password)) }
}))

const v$ = useVuelidate<CreateAccountViewModel>(rules, form)
//#endregion

const router = useRouter()
const currentRoute = useRoute()
const userStore = useUserStore()

const inviteId = ref(INVITE_ONLY ? (currentRoute.params.inviteId as string) : undefined)
const isValidatingInvite = ref(false)
const isCreatingAccount = ref(false)
const errorMessage = ref<string | undefined>()

async function createAccount(e: Event) {
  e.preventDefault()

  try {
    v$.value.$validate()
    if (v$.value.$error) return

    isCreatingAccount.value = true
    await userService.signUp(inviteId.value, form.value)
    await userStore.login(form.value.email, form.value.password)

    return userStore.isLoggedIn
      ? router.replace({ name: 'feed' })
      : router.replace({ name: 'login' })
      
  } catch (ex) {
    const error = (ex as { response: { data: { message: string }}})?.response?.data?.message ?? 'Sorry, something went wrong.'
    errorMessage.value = error
  } finally {
    isCreatingAccount.value = false
  }
}

//ON CREATED
;(async () => {
  if (!INVITE_ONLY) return

  if (!inviteId.value) {
    toast.error("Sorry, that invite is not valid.") 
    return router.replace('/login')
  }

  isValidatingInvite.value = true

  try {
    const isValidInviteId = await userService.validateInvite(inviteId.value)
    if (!isValidInviteId) {
      toast.error("Sorry, that invite is not valid.")  
      return router.replace('/login')
    }
  } catch {
    toast.error("Sorry, that invite is not valid.")  
    return router.replace('/login')
  } finally {
    isValidatingInvite.value = false
  }
})()
</script>

<template>
  <SplashLayout :show-flavor-text="false" :increase-contrast="true">
    <form @submit="createAccount" v-if="!isValidatingInvite">
      <div class="row mb-3">
        <div class="col-12">
          <VError v-if="errorMessage">{{ errorMessage }}</VError>
        </div>
      </div>
      <div class="row gy-1">
        <div class="col-6">
          <label class="form-label" for="firstName">First Name</label>

          <input
            type="text"
            id="firstName"
            v-model="form.firstName"
            placeholder="Pappy"
            maxlength="25"
            class="form-control"
            :class="{ 'is-invalid': v$.firstName.$error }"
          />
        </div>
        <div class="col-6">
          <label class="form-label" for="lastName">Last Name</label>

          <input
            type="text"
            id="lastName"
            v-model="form.lastName"
            placeholder="Van Winkle"
            maxlength="25"
            class="form-control"
            :class="{ 'is-invalid': v$.lastName.$error }"
          />
        </div>
        <div class="col-12">
          <label class="form-label" for="handle">Handle</label>
          <VInputWrapper icon="at">
            <input
              type="text"
              id="handle"
              v-model="form.handle"
              placeholder="TheBourbonHunter"
              maxlength="25"
              class="form-control"
              :class="{ 'is-invalid': v$.handle.$error }"
            />
          </VInputWrapper>
        </div>
        <div class="col-12">
          <label class="form-label" for="email">Email</label>
          <VInputWrapper icon="envelope">
            <input
              type="text"
              id="email"
              v-model="form.email"
              placeholder="pvanwinkle@gmail.com"
              maxlength="100"
              class="form-control"
              :class="{ 'is-invalid': v$.email.$error }"
            />
          </VInputWrapper>
        </div>
        <div class="col-12">
          <label class="form-label" for="password"
            >Password <span class="text-primary small ms-2">(8+ Characters)</span></label
          >
          <VInputWrapper icon="at">
            <input
              type="password"
              id="password"
              v-model="form.password"
              placeholder="Password"
              maxlength="100"
              class="form-control"
              :class="{ 'is-invalid': v$.password.$error }"
            />
          </VInputWrapper>
        </div>
        <div class="col-12">
          <label class="form-label" for="confirmPassword">Confirm Password</label>
          <VInputWrapper icon="at">
            <input
              type="password"
              id="confirmPassword"
              v-model="form.confirmPassword"
              placeholder="Password"
              maxlength="100"
              class="form-control"
              :class="{ 'is-invalid': v$.confirmPassword.$error }"
            />
          </VInputWrapper>
        </div>
        <div class="col-12 mt-3">
          <VButton
            class="btn btn-light w-100"
            :is-loading="isCreatingAccount"
            type="submit"
            loading-text="Creating account..."
            >Create Account</VButton
          >
        </div>
      </div>
    </form>
    <div class="text-center pb-5" v-if="isValidatingInvite">
      <div class="spinner-border text-stone-0" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span class="d-block">Validating invite URL...</span>
    </div>
    <div class="row mt-4">
        <div class="col fs-7 text-center">
          <router-link :to="{ name: 'login' }">
            <VIcon prefix="br" icon="angle-left" class="me-2 ms-n2"></VIcon>
            Back to login
          </router-link>
        </div>
      </div>
  </SplashLayout>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.form-label {
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 1000;
  color: map-get($custom-colors, 'stone-300');
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.error-text {
  position: absolute;
  bottom: -15px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--bs-form-invalid-color);
}
</style>
