<script setup lang="ts">
import type Bottle from '@/models/Bottle'
import type Spirit from '@/models/Spirit'
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import bottleService from '@/services/BottleService'
import spiritService from '@/services/SpiritService'
import Rating from '@/models/Rating'
import VIcon from '@/components/VIcon.vue'
import { helpers, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import VueSlider from 'vue-slider-component'
import VButton from '@/components/VButton.vue'
import { useToast } from 'vue-toastification'
import ratingService from '@/services/RatingService'

const toast = useToast()
const { user: currentUser } = storeToRefs(useUserStore())
const route = useRoute()
const router = useRouter()
const isSpiritRating = ref(route.name === 'newSpiritRating')
const isBottleRating = ref(route.name === 'newBottleRating')
const hasTastingNotes = ref(false)
const isSaving = ref(false)

const spirit = ref<Spirit | undefined>()
const bottle = ref<Bottle | undefined>()
const rating = ref<Rating>(undefined!)

const headerStyle = computed(() => {
  let backgroundImageCss =
    'linear-gradient(180deg, #29252470 40%, #292524), linear-gradient(0deg, rgba(0,0,0,0.9), rgba(0,0,0,0.9))'
  if (spirit.value?.image) backgroundImageCss += `, url(${spirit.value.imgSrc})`

  return { 'background-image': backgroundImageCss }
})

const rules = computed(() => ({
  aromaNotes: {
    maxLength: helpers.withMessage('Max length is 500 characters', maxLength(500))
  },
  tasteNotes: {
    maxLength: helpers.withMessage('Max length is 500 characters', maxLength(500))
  },
  finishNotes: {
    maxLength: helpers.withMessage('Max length is 500 characters', maxLength(500))
  }
}))

const v$ = useVuelidate<Rating>(rules, rating)

function toggleTastingNotes() {
  hasTastingNotes.value = !hasTastingNotes.value

  if (!hasTastingNotes.value) {
    rating.value.aromaNotes = undefined;
    rating.value.tasteNotes = undefined;
    rating.value.finishNotes = undefined;
    rating.value.summary = undefined;
  }
}
async function save(e: Event) {
  e.preventDefault()

  isSaving.value = true

  v$.value.$validate()
  if (v$.value.$error) {
    toast.error('Unable to save. See form for errors.')
    isSaving.value = false
    return false
  }

  try {
    const justRating: Rating = {...rating.value, bottle: undefined, spirit: undefined, user: undefined }
    await ratingService.add(justRating)
    toast.success('Rating published!')
    return router.back()
  } catch {
    toast.error('Something went wrong')
  } finally {
    isSaving.value = false
  }
}

const scoringItems = computed(() => [
  {
    label: 'Aroma',
    info: 'The smell details'
  },
  {
    label: 'Taste',
    info: 'The taste details'
  },
  {
    label: 'Finish',
    info: 'The finish details'
  }
])

const overallScore = computed(() => {
  const avg = (rating.value.aromaScore! + rating.value.tasteScore! + rating.value.finishScore!) / 3
  if (Number.isSafeInteger(avg)) return avg.toString()
  return avg.toFixed(1)
})

//ON CREATED
;(async () => {
  const spiritId = route.params.spiritId as string
  const bottleId = route.params.bottleId as string
  if (!spiritId && !bottleId) return router.back()

  if (isBottleRating.value) {
    try {
      bottle.value = await bottleService.get(bottleId)
      spirit.value = bottle.value.spirit
      rating.value = new Rating(currentUser.value!, spirit.value, bottle.value)
    } catch {
      return router.back()
    }
  } else if (isSpiritRating.value) {
    try {
      spirit.value = await spiritService.get(spiritId)
      rating.value = new Rating(currentUser.value!, spirit.value)
    } catch {
      return router.back()
    }
  } else {
    return router.back()
  }

  rating.value.aromaScore = 5
  rating.value.tasteScore = 5
  rating.value.finishScore = 5
})()
</script>

<template>
  <TitleBarLayout title="New Rating">
    <template #back>
      <a @click="$router.back" class="d-block text-white">
        <VIcon prefix="br" icon="angle-left"></VIcon>
      </a>
    </template>

    <div class="p-0 d-flex flex-column" v-if="spirit">
      <!-- #region HEADER -->
      <div class="bg-stone-800">
        <div class="result header" :style="headerStyle">
          <div
            style="width: 100px; height: 100px"
            class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
          >
            <div
              class="img-container w-100 h-100"
              :style="{ 'background-image': `url(${spirit.imgSrc})` }"
            ></div>
          </div>
          <div class="spirit-header ms-2 text-end me-4">
            <h1 class="my-0 fw-black text-primary">{{ spirit.name }}</h1>
            <div style="font-size: 13px">
              <div class="d-flex justify-content-end">
                <div>
                  <span>{{ spirit.type }}</span>
                </div>
                <template v-if="spirit.distiller">
                  <div class="mx-1 title" style="margin-top: 2px">
                    <VIcon prefix="sr" icon="bullet"></VIcon>
                  </div>
                  <div>
                    <span>{{ spirit.distiller }}</span>
                  </div>
                </template>
                <template v-if="spirit.region">
                  <div class="mx-1 title" style="margin-top: 2px">
                    <VIcon prefix="sr" icon="bullet"></VIcon>
                  </div>
                  <div>
                    <span>{{ spirit.region }}</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="mt-2 mx-n1">
              <span
                class="badge bg-secondary text-stone-900 fw-black text-uppercase opacity-75 mx-1"
                v-for="tag in spirit.tags"
                :key="tag"
                >{{ tag }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- #endregion -->

      <!-- #region FORM -->
      <div class="flex-grow-1">
        <form @submit="save" class="h-100 container my-0">
          <div class="row gy-2 h-100">
            <div
              class="col-12 bg-stone-1000 py-3 d-flex align-items-center justify-content-between"
            >
              <!-- <button type="button" class="d-block btn btn-outline-stone-0" disabled>
                <VIcon prefix="rr" icon="camera" class="me-2"></VIcon>Add a picture
              </button> -->
              <span class="small text-stone-500">(Pictures coming soon!)</span>
              <div>
                <a role="button" class="ms-auto fw-bold" @click="toggleTastingNotes">
                  {{ hasTastingNotes ? 'Remove Notes' : 'Include Notes' }}
                </a>
              </div>
            </div>

            <div
              v-for="(scoringItem, idx) in scoringItems"
              :key="scoringItem.label"
              class="col-12 border-top border-stone-800 pt-3 px-3"
              :class="{ 'mt-0': idx === 0 }"
            >
              <div class="d-flex align-items-center">
                <label class="form-label text-white">{{ scoringItem.label }}</label>
              </div>
              <div class="d-flex align-items-center mt-n2">
                <!-- @vue-ignore -->
                <vue-slider
                  class="flex-grow-1"
                  :contained="true"
                  :adsorb="false"
                  v-model="rating[`${scoringItem.label.toLowerCase()}Score`]"
                  :max="10"
                  :min="1"
                  :interval="0.5"
                  :dotSize="20"
                  height="10px"
                  :duration="0"
                  tooltip="none"
                />
                <div class="score-container">
                  <span class="score">{{
                    rating[`${scoringItem.label.toLowerCase()}Score` as keyof Rating]
                  }}</span>
                </div>
              </div>
              <div class="mt-2" v-if="hasTastingNotes">
                <!-- @vue-ignore -->
                <textarea
                  style="min-height: 85px"
                  :placeholder="`Add your ${scoringItem.label.toLowerCase()} notes here.`"
                  v-model="rating[`${scoringItem.label.toLowerCase()}Notes`]"
                  maxlength="500"
                ></textarea>
                <span
                  v-if="v$[`${scoringItem.label.toLowerCase()}Notes` as keyof Rating].$error"
                  class="error-text"
                >
                  {{ v$[`${scoringItem.label.toLowerCase()}Notes`].$errors[0] }}
                </span>
              </div>
            </div>

            <div class="col-12 border-top border-stone-800 pt-3">
              <label class="text-center form-label text-primary">Your Rating</label>
              <div class="d-flex align-items-center justify-content-center">
                <div class="overall-score">
                  {{ overallScore }}
                </div>
              </div>
              <div class="mt-3" v-if="hasTastingNotes">
                <textarea
                  style="min-height: 85px"
                  placeholder="Provide a summary of your review here."
                  v-model="rating.summary"
                ></textarea>
              </div>
            </div>

            <div class="col-12 d-flex flex-column pb-3">
              <VButton type="submit" class="btn btn-primary w-100 d-block mt-auto" loadingText="Publishing..." :is-loading="isSaving">
                Publish Rating
              </VButton>
            </div>
          </div>
        </form>
      </div>
      <!-- #endregion -->
    </div>
  </TitleBarLayout>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

label {
  display: block;
  color: map-get($custom-colors, 'amber-500');
}

.img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100px;
  max-width: 50px;
}

.result {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  background-color: map-get($custom-colors, 'stone-800');
}

.spirit-header {
  flex-grow: 1;
}

.result.header {
  background-repeat: no-repeat;
  background-size: cover, cover, 500px;
  background-position:
    center,
    center,
    0% 65%;
  background-color: map-get($custom-colors, 'stone-300');
  border: 0 !important;
}

.result.header h1 {
  font-size: 25px;
  text-shadow: 0 3px 0 black;
}

.result:not(:last-child) {
  border-bottom: 1px solid map-get($custom-colors, 'stone-900');
}

.result:not(:first-child) {
  border-top: 1px solid map-get($custom-colors, 'stone-700');
}

.title {
  display: inline-block;
  font-size: 10px;
  font-weight: 1000;
  text-transform: uppercase;
  color: map-get($custom-colors, 'stone-500');
  margin-bottom: -3px;
  margin-right: 5px;
}

.form-label {
  margin-bottom: 2px;
  font-size: 14px;
  color: map-get($custom-colors, 'stone-300');
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-weight: 1000;
}

.error-text {
  position: absolute;
  top: -17px;
  right: 0;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--bs-form-invalid-color);
}

.score-container {
  width: 70px;
  margin-left: 20px;
  position: relative;
  text-align: right;
}

.score-container::after {
  content: '/ 10';
  position: absolute;
  right: 0;
  bottom: 0;
  color: map-get($custom-colors, 'stone-400');
  transform: translateY(-5px);
}

.score-container .score {
  display: block;
  font-size: 25px;
  font-weight: 1000;
  transform: translateX(-30px);
}

.overall-score {
  display: flex;
  width: 125px;
  height: 125px;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 1000;
  color: white;
  border: 4px solid map-get($custom-colors, 'stone-700');
  background-color: map-get($custom-colors, 'stone-1000');
  border-radius: 100%;
}
</style>
