import HttpService, { type IHttpService } from '@/services/HttpService';
import Spirit from '@/models/Spirit';
import type SearchResult from '@/models/SearchResult';

const controller = 'search';

class SearchService {
  private readonly _http: IHttpService;

  constructor() {
    this._http = new HttpService(controller);
  }

  public async search(query: string): Promise<SearchResult[]> {
    const results = await this._http.getWithParams<SearchResult[]>('', { query })
    return results.map(s => {
      if (s.type === "spirit") s.obj = Spirit.clone(s.obj as Spirit);
      return s;
    })
  }
}

export default new SearchService();