<script setup lang="ts">
import VBottomNavigation from '@/components/VBottomNavigation.vue'
import VIcon from '@/components/VIcon.vue'
import type { RouteLocationRaw } from 'vue-router'

withDefaults(
  defineProps<{
    title: string
    backRoute?: RouteLocationRaw
    showLogout?: boolean
    hideBottomNav?: boolean
  }>(),
  {
    backRoute: () => {
      return { name: 'feed' } as RouteLocationRaw
    },
    showLogout: false,
    hideBottomNav: false
  }
)

const emit = defineEmits(['logout'])
function logout() {
  emit('logout')
}
</script>

<template>
  <div class="d-flex flex-column container-xxl" style="height: 100dvh">
    <div class="row">
      <header class="py-3 shadow position-relative">
        <slot name="back">
          <router-link :to="backRoute" class="d-block text-white">
            <VIcon prefix="br" icon="angle-left"></VIcon>
          </router-link>
        </slot>
        <h4 class="d-block mx-auto my-0">{{ title }}</h4>
        <a v-if="showLogout" class="logout" role="button" @click="logout">
          <VIcon class="fs-4 text-stone-400" prefix="br" icon="exit"></VIcon>
        </a>
      </header>
    </div>
    <main class="content row">
      <slot></slot>
    </main>
    <div class="row">
      <VBottomNavigation v-if="!hideBottomNav" id="bottom-navigation"></VBottomNavigation>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content {
  flex-grow: 1;
  overflow: auto;
}

header {
  display: flex;
  align-items: center;
}

header::after {
  content: '';
  flex: 1;
}

.logout {
  display: block;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
</style>

<style lang="scss">
header > *:first-child {
  flex: 1;
  font-size: 20px;
}</style>