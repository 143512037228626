<script setup lang="ts" generic="T extends Record<string, any>">
import { ref, computed, type Ref, onMounted } from 'vue'
import VInputWrapper from '@/components/VInputWrapper.vue'

const props = withDefaults(
  defineProps<{
    minSearchLength?: number
    searchProperty: keyof T
    searchFunction: (query: string) => Promise<T[]>
    keyProperty: keyof T
    placeholder?: string
    focusOnLoad?: boolean
  }>(),
  {
    minSearchLength: 2,
    placeholder: "",
    focusOnLoad: false
  }
)

const collection = ref<T[]>([]) as Ref<T[]>
const previouslyRunQueries = ref<string[]>([])
const query = ref('')
const isSearching = ref(false)
const searchInput = ref<HTMLInputElement | null>(null);

const searchResults = computed(() => {
  if (query.value.length < props.minSearchLength) return []
  return collection.value.filter(
    (i) =>
      i[props.searchProperty] &&
      i[props.searchProperty].toLowerCase().indexOf(query.value.toLowerCase()) >= 0
  )
})

async function doQuery() {
  if (query.value.length < props.minSearchLength) return

  const queryPrefix = query.value.substring(0, props.minSearchLength).toLowerCase()
  const alreadyQueriedThis = previouslyRunQueries.value.includes(queryPrefix)
  if (alreadyQueriedThis) return

  previouslyRunQueries.value.push(queryPrefix)
  isSearching.value = true

  const results = (await props.searchFunction(queryPrefix)) as T[]
  const keys = collection.value.map((i) => i[props.keyProperty] as string)
  results.forEach((i) => {
    if (!keys.includes(i[props.keyProperty])) collection.value.push(i)
  })

  isSearching.value = false
}

onMounted(() => {
  searchInput.value?.focus();
})
</script>

<template>
  <div>
    <VInputWrapper prefix="rr" icon="search" class="fs-5">
      <input
        ref="searchInput"
        class="form-control rounded-0 py-3 fs-5 ps-5"
        @input="doQuery"
        v-model="query"
        :placeholder="placeholder"
      />
      <span class="search-spinner" v-if="isSearching">
        <span class="spinner-border" role="status"></span>
      </span>
    </VInputWrapper>
  </div>

  <div v-if="query.length">
    <slot v-if="!isSearching" name="add-item" :query="query"></slot>
    <slot name="result-item" v-for="(item, idx) in searchResults" :key="`item_${idx}`" :item="item"></slot>
  </div>

  <slot v-if="!query.length" name="no-query">
  </slot>
</template>

<style scoped>
.search-spinner {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-45%);
}
</style>
