<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import VIcon from '@/components/VIcon.vue'
import spiritService from '@/services/SpiritService'
import type Spirit from '@/models/Spirit'
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

const route = useRoute()
const spirit = ref<Spirit | undefined>()
const { user: currentUser } = storeToRefs(useUserStore())

const headerStyle = computed(() => {
  let backgroundImageCss =
    'linear-gradient(180deg, #29252470 40%, #292524), linear-gradient(0deg, rgba(0,0,0,0.9), rgba(0,0,0,0.9))'
  if (spirit.value?.image) backgroundImageCss += `, url(${spirit.value.imgSrc})`

  return { 'background-image': backgroundImageCss }
})

//ON CREATED
;(async () => {
  spirit.value = await spiritService.get(route.params.id as string)
})()
</script>

<template>
  <TitleBarLayout title="View Spirit">
    <template #back>
      <a @click="$router.back" class="d-block text-white">
        <VIcon prefix="br" icon="angle-left"></VIcon>
      </a>
    </template>
    <div v-if="spirit" class="p-0">
      <!-- #region HEADER -->
      <div class="bg-stone-800">
        <div class="result header" :style="headerStyle">
          <div
            style="width: 100px; height: 100px"
            class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
          >
            <div
              class="img-container w-100 h-100"
              :style="{ 'background-image': `url(${spirit.imgSrc})` }"
            ></div>
          </div>
          <div class="spirit-header ms-2 text-end me-4">
            <h1 class="my-0 fw-black text-primary">{{ spirit.name }}</h1>
            <div style="font-size: 13px">
              <div class="d-flex justify-content-end">
                <div>
                  <span>{{ spirit.type }}</span>
                </div>
                <template v-if="spirit.distiller">
                  <div class="mx-1 title" style="margin-top: 2px">
                    <VIcon prefix="sr" icon="bullet"></VIcon>
                  </div>
                  <div>
                    <span>{{ spirit.distiller }}</span>
                  </div>
                </template>
                <template v-if="spirit.region">
                  <div class="mx-1 title" style="margin-top: 2px">
                    <VIcon prefix="sr" icon="bullet"></VIcon>
                  </div>
                  <div>
                    <span>{{ spirit.region }}</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="mt-2 mx-n1">
              <span
                class="badge bg-secondary text-stone-900 fw-black text-uppercase opacity-75 mx-1"
                v-for="tag in spirit.tags"
                :key="tag"
                >{{ tag }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- #endregion -->

      <!-- #SPIRIT -->
      <div class="py-3 px-1">
        <div class="container">
          <div class="row gy-4">
            <div class="col-6">
              <label class="form-label">Distiller</label>
              <VIcon v-if="spirit.distiller" prefix="rr" icon="warehouse-alt"></VIcon>
              <span :class="{ 'is-empty': !spirit.distiller }">{{ spirit.distiller }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Country</label>
              <VIcon v-if="spirit.country" prefix="rr" icon="flag"></VIcon>
              <span :class="{ 'is-empty': !spirit.country }">{{ spirit.country }}</span>
            </div>
            <div class="col-12">
              <label class="form-label">Region</label>
              <VIcon v-if="spirit.region || spirit.subregion" prefix="rr" icon="marker"></VIcon>
              <span :class="{ 'is-empty': !spirit.subregion && !spirit.region }">
                <span v-if="spirit.subregion">{{ spirit.subregion }}</span>
                <span v-if="spirit.subregion && spirit.region">, </span>
                <span v-if="spirit.region">{{ spirit.region }}</span>
              </span>
            </div>
            <div class="col-6">
              <label class="form-label">ABV</label>
              <VIcon v-if="spirit.abv" prefix="rr" icon="percentage"></VIcon>
              <span :class="{ 'is-empty': !spirit.abv }">{{ spirit.abv?.toFixed(1) }}</span>
              <span v-if="spirit.abv2"> - {{ spirit.abv2?.toFixed(1) }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Age</label>
              <VIcon v-if="spirit.ageInYears" prefix="rr" icon="time-past"></VIcon>
              <span :class="{ 'is-empty': !spirit.ageInYears }">
                {{ spirit.ageInYears }} <span v-if="spirit.ageInYears">years</span>
              </span>
            </div>
            <div class="col-12">
              <label class="form-label">Description</label>
              <div class="d-flex align-items-stretch">
                <div
                  v-if="spirit.description"
                  style="width: 3px"
                  class="bg-stone-700 me-2 my-1 flex-shrink-0 flex-grow-0"
                ></div>
                <div
                  class="fw-normal"
                  style="line-height: 23px"
                  :class="{ 'is-empty': !spirit.description }"
                >
                  {{ spirit.description }}
                </div>
              </div>
            </div>
            <div class="col-12">
                <router-link :to="{ name: 'newSpiritRating', params: { spiritId: spirit.id }}" class="btn btn-primary w-100">Rate This Spirit</router-link>
            </div>
            <div class="col-12">
                <router-link :to="{ name: 'newBottle', query: { spiritId: spirit.id }}" class="btn btn-outline-stone-0 w-100">Add To Your Collection</router-link>
            </div>
            <div class="col-12">
                <router-link :to="{ name: 'editSpirit', params: { id: spirit.id }}" class="btn btn-outline-primary w-100">
                  <span v-if="!currentUser?.isAdmin">Suggest Edits</span>
                  <span v-if="currentUser?.isAdmin">Edit Spirit</span>
                </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- #endregion -->
    </div>
  </TitleBarLayout>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

label {
  display: block;
  color: map-get($custom-colors, 'amber-500') !important;
}

.img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100px;
  max-width: 50px;
}

.result {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  background-color: map-get($custom-colors, 'stone-800');
}

.spirit-header {
  flex-grow: 1;
}

.result.header {
  background-repeat: no-repeat;
  background-size: cover, cover, 500px;
  background-position:
    center,
    center,
    0% 65%;
  background-color: map-get($custom-colors, 'stone-300');
  border: 0 !important;
}

.result.header h1 {
  font-size: 25px;
  text-shadow: 0 3px 0 black;
}

.result:not(:last-child) {
  border-bottom: 1px solid map-get($custom-colors, 'stone-900');
}

.result:not(:first-child) {
  border-top: 1px solid map-get($custom-colors, 'stone-700');
}

.title {
  display: inline-block;
  font-size: 10px;
  font-weight: 1000;
  text-transform: uppercase;
  color: map-get($custom-colors, 'stone-500');
  margin-bottom: -3px;
  margin-right: 5px;
}

.form-label {
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 1000;
  color: map-get($custom-colors, 'stone-300');
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

label + i.fi {
  margin-right: 8px;
  color: map-get($custom-colors, 'stone-500');
}

.is-empty::after {
  content: 'N/A';
  font-weight: normal;
  color: map-get($custom-colors, 'stone-500');
  font-size: 15px;
  display: block;
  margin-top: 5px;
}
</style>
