import axios, { type AxiosInstance, type AxiosResponse } from 'axios'
import { parseISO } from 'date-fns'
import { nullsToUndefined } from '@/helpers'

export interface IHttpService {
  get<T>(endpoint: string, id?: string): Promise<T>
  getWithParams<T>(endpoint: string, params: {}): Promise<T>
  post<T, R>(endpoint: string, data: T): Promise<R>
  postAndGetLocation<T>(endpoint: string, data: T): Promise<string>
  postFile<T>(endpoint: string, file: File): Promise<T>
  patch<T, R>(endpoint: string, data: T): Promise<R>
  put<T, R>(endpoint: string, data: T): Promise<R>
  delete(endpoint: string): Promise<void>
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

function isIsoDateString(value: object): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

export function handleDates(body: { [key: string]: any }) {
  if (body === null || body === undefined || typeof body !== 'object') return body

  for (const key of Object.keys(body)) {
    let value = body[key]
    if (isIsoDateString(value)) {
      if (!value.endsWith('Z')) value += 'Z'
      body[key] = parseISO(value)
    } else if (typeof value === 'object') handleDates(value)
  }
}

const apiBasePath = '/api'

export default class HttpService implements IHttpService {
  private _axios: AxiosInstance
  private _basePath: string

  constructor(controller: string) {
    const axiosInstance = axios.create()

    axiosInstance.interceptors.request.use(
      (config) => {
        const jwt = localStorage.getItem('jwt')
        if (jwt) config.headers['Authorization'] = `Bearer ${jwt}`
        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )

    axiosInstance.interceptors.response.use(
      (response) => {
        handleDates(response.data)
        nullsToUndefined(response.data)
        return response
      },
      async function (error) {
        const originalRequest = error.config

        if (error.response.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true

          const authenticationResponse = await axiosInstance.post(
            '/api/users/refreshTokens/use',
            null
          )
          localStorage.setItem('jwt', authenticationResponse.data.jwtToken)
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${authenticationResponse.data.jwtToken}`
          return axiosInstance(originalRequest)
        }

        return Promise.reject(error)
      }
    )

    this._axios = axiosInstance
    this._basePath = `${apiBasePath}/${controller}/`
  }

  public async get<T>(endpoint: string, id?: string): Promise<T> {
    if (endpoint && endpoint.length && !endpoint.includes('?')) endpoint += '/'
    const rel = id && id.length > 0 ? endpoint + id : endpoint
    const response = await this._axios.get<T>(`${this._basePath}${rel}`)
    return response.data
  }

  public async getWithParams<T>(endpoint: string, params: {}): Promise<T> {
    const queryString = new URLSearchParams(params).toString()
    const response = await this._axios.get<T>(`${this._basePath}${endpoint}?${queryString}`)
    return response.data
  }

  public async post<T, R>(endpoint: string, data: T): Promise<R> {
    const response = await this._axios.post<T, AxiosResponse<R>>(
      `${this._basePath}${endpoint}`,
      data
    )
    return response.data
  }

  public async postAndGetLocation<T>(endpoint: string, data: T): Promise<string> {
    const response = await this._axios.post<T, AxiosResponse<null>>(
      `${this._basePath}${endpoint}`,
      data
    )
    return response.headers['location']
  }

  public async postFile<T, R>(endpoint: string, file: File): Promise<R> {
    const formData = new FormData()
    formData.append('file', file, file.name)
    const response = await this._axios.post<T, AxiosResponse<R>>(
      `${this._basePath}${endpoint}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    return response.data
  }

  public async patch<T, R>(endpoint: string, data?: T): Promise<R> {
    if (typeof data !== 'undefined') {
      const response = await this._axios.patch<T, AxiosResponse<R>>(
        `${this._basePath}${endpoint}`,
        data
      )
      return response.data
    }

    const response = await this._axios.patch<T, AxiosResponse<R>>(
      `${this._basePath}${endpoint}`,
      data
    )
    return response.data
  }

  public async put<T, R>(endpoint: string, data: T): Promise<R> {
    const response = await this._axios.put<T, AxiosResponse<R>>(
      `${this._basePath}${endpoint}`,
      data
    )
    return response.data
  }

  public async delete(endpoint: string): Promise<void> {
    await this._axios.delete(`${this._basePath}${endpoint}`)
  }
}
