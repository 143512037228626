<script lang="ts" setup>
import type FriendRequest from '@/models/FriendRequest'
import type FeedItem from '@/models/FeedItem'
import VFeedItem from '@/components/VFeedItem.vue'
import type UserSummary from '@/models/UserSummary'
import type User from '@/models/User'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const props = defineProps<{
  friendRequest: FriendRequest
  feedItem: FeedItem
  preferUser?: User | UserSummary
}>()

const { friendRequests: friends } = storeToRefs(useUserStore())

const preferredUser = computed(() => {
  if (props.preferUser) {
    return props.preferUser.id === props.friendRequest.recipient!.id
      ? props.friendRequest.recipient!
      : props.friendRequest.sender!
  }

  if (
    friends.value.some((f) =>
      [f.senderId, f.recipientId].includes(props.friendRequest.recipient!.id)
    )
  )
    return props.friendRequest.recipient!

  return props.friendRequest.sender!
})

const otherUser = computed(() => {
  if (preferredUser.value === props.friendRequest.recipient) return props.friendRequest.sender!

  return props.friendRequest.recipient!
})
</script>

<template>
  <VFeedItem :feed-item="feedItem" :user="preferredUser">
    <template #header>
      <router-link
        class="fw-bold text-white"
        :to="{ name: 'profile', params: { handle: preferredUser.handle } }"
        >{{ preferredUser.handle }}</router-link
      >
      <span class="text-stone-300 d-inline-block mx-2"> is now friends with </span>
      <router-link
        class="fw-bold text-white"
        :to="{ name: 'profile', params: { handle: otherUser.handle } }"
        >{{ otherUser.handle }}</router-link
      >
      <span>.</span>
    </template>
  </VFeedItem>
</template>
