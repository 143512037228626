import type User from '@/models/User'

export default class Comment {
  public id?: string
  public user: User
  public feedItemId: string
  public text: string
  public created?: Date

  constructor(user: User, feedItemId: string, text: string, date?: Date, id?: string) {
    this.user = user
    this.feedItemId = feedItemId
    this.text = text
    this.created = date
    this.id = id
  }
}
