<script setup lang="ts">
import ViewBottleLayout from '@/views/layouts/ViewBottleLayout.vue'
import { computed, ref } from 'vue'
import type Bottle from '@/models/Bottle'
import bottleService from '@/services/BottleService'
import { useRoute, useRouter } from 'vue-router'
import VIcon from '@/components/VIcon.vue'
import { format } from 'date-fns'

const route = useRoute()
const router = useRouter()
const bottle = ref<Bottle | undefined>()
const isLoading = ref(false)

const age = computed(() => {
  if (!bottle.value) return undefined

  let output = ''
  if (bottle.value.ageYears)
    output += `${bottle.value.ageYears} <span class="detail text-white">yrs</span>`
  if (bottle.value.ageMonths)
    output += `${output.length ? ' ' : ''}${
      bottle.value.ageMonths
    } <span class="detail text-white">mos</span>`
  return output === '' ? undefined : output
})

const spirit = computed(() => {
  return bottle.value?.spirit
})

const headerStyle = computed(() => {
  let backgroundImageCss =
    'linear-gradient(180deg, #29252470 40%, #292524), linear-gradient(0deg, rgba(0,0,0,0.9), rgba(0,0,0,0.9))'
  if (spirit.value?.image) backgroundImageCss += `, url(${spirit.value.imgSrc})`

  return { 'background-image': backgroundImageCss }
})

//ON CREATED
;(async () => {
  try {
    isLoading.value = true
    bottle.value = await bottleService.get(route.params.id as string)
  } catch {
    //bottle not found
    router.push({ name: 'feed' })
  } finally {
    isLoading.value = false
  }
})()
</script>

<template>
  <ViewBottleLayout :bottle="bottle">
    <div v-if="bottle && spirit && !isLoading" class="p-0">
      <!-- #region HEADER -->
      <div class="bg-stone-800">
        <div class="result header" :style="headerStyle">
          <div
            style="width: 100px; height: 100px"
            class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
          >
            <div
              class="img-container w-100 h-100"
              :style="{ 'background-image': `url(${spirit.imgSrc})` }"
            ></div>
          </div>
          <div class="spirit-header ms-2 text-end me-4">
            <h1 class="my-0 fw-black text-primary">
              <router-link :to="{ name: 'spirit', params: { id: spirit.id } }">
                {{ spirit.name }}
                <VIcon
                  prefix="br"
                  icon="link"
                  class="fs-6 ms-1 d-inline-block"
                  style="transform: translateY(-3px)"
                ></VIcon>
              </router-link>
            </h1>
            <div style="font-size: 13px">
              <div class="d-flex justify-content-end">
                <div>
                  <span>{{ spirit.type }}</span>
                </div>
                <template v-if="spirit.distiller">
                  <div class="mx-1 title" style="margin-top: 2px">
                    <VIcon prefix="sr" icon="bullet"></VIcon>
                  </div>
                  <div>
                    <span>{{ spirit.distiller }}</span>
                  </div>
                </template>
                <template v-if="spirit.region">
                  <div class="mx-1 title" style="margin-top: 2px">
                    <VIcon prefix="sr" icon="bullet"></VIcon>
                  </div>
                  <div>
                    <span>{{ spirit.region }}</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="mt-2 mx-n1">
              <span
                class="badge bg-secondary text-stone-900 fw-black text-uppercase opacity-75 mx-1"
                v-for="tag in spirit.tags"
                :key="tag"
                >{{ tag }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- #endregion -->

      <!-- #region BOTTLE -->
      <div class="py-3 px-1">
        <div class="container">
          <div class="row gy-4">
            <div class="col-12">
              <h4 class="my-1 p-0 fs-6 text-stone-400">About this bottle:</h4>
            </div>
            <div class="col-6 mt-2">
              <label class="form-label">ABV</label>
              <VIcon v-if="Number(bottle.abv) < 60" prefix="rr" icon="percentage"></VIcon>
              <img
                v-if="Number(bottle.abv) >= 60"
                src="/icons/fire.png"
                style="margin-right: 8px; width: 16px; margin-left: -2px; margin-top: -4px"
              />
              <span>{{ bottle.abv?.toFixed(1) }}</span>
              <span v-if="Number(bottle.abv) >= 60" class="ms-1 small text-white">%</span>
            </div>
            <div class="col-6 mt-2">
              <label class="form-label">Age</label>
              <VIcon v-if="age" prefix="rr" icon="time-past"></VIcon>
              <span v-html="age" :class="{ 'is-empty': !age }"></span>
            </div>
            <div class="col-6">
              <label class="form-label">Size (ml)</label>
              <VIcon prefix="rr" icon="bottle"></VIcon>
              <span>{{ bottle.sizeInMl }}<span class="small text-white">ml</span></span>
            </div>
            <div class="col-6">
              <label class="form-label">Batch</label>
              <VIcon v-if="bottle.batch" prefix="rr" icon="label"></VIcon>
              <span :class="{ 'is-empty': !bottle.batch }">{{ bottle.batch }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Remaining</label>
              <VIcon v-if="bottle.remaining" prefix="rr" icon="humidity"></VIcon>
              <span :class="{ 'is-empty': !bottle.remaining }">
                <span v-if="bottle.remaining"
                  >{{ bottle.remaining }}
                  <span class="detail">%</span>
                </span>
              </span>
            </div>
            <div class="col-6">
              <label class="form-label">Seller</label>
              <VIcon v-if="bottle.seller" prefix="rr" icon="shopping-cart"></VIcon>
              <span :class="{ 'is-empty': !bottle.seller }">{{ bottle.seller }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Barrel Pick</label>
              <img
                v-if="bottle.pick"
                src="/icons/star.png"
                style="margin-right: 8px; width: 16px; margin-left: -2px; margin-top: -4px"
              />
              <span :class="{ 'is-empty': !bottle.pick }">{{ bottle.pick }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Paid</label>
              <VIcon v-if="bottle.pricePaid" prefix="rr" icon="dollar"></VIcon>
              <span :class="{ 'is-empty': !bottle.pricePaid }">{{
                bottle.pricePaid ? `${bottle.pricePaid.toFixed(2)}` : ''
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Distllation Date</label>
              <VIcon v-if="bottle.distillationDate" prefix="rr" icon="calendar"></VIcon>
              <span :class="{ 'is-empty': !bottle.distillationDate }">{{
                bottle.distillationDate ? format(bottle.distillationDate, 'PPP') : ''
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Bottling Date</label>
              <VIcon v-if="bottle.bottlingDate" prefix="rr" icon="calendar"></VIcon>
              <span :class="{ 'is-empty': !bottle.bottlingDate }">{{
                bottle.bottlingDate ? format(bottle.bottlingDate, 'PPP') : ''
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Purchase Date</label>
              <VIcon v-if="bottle.purchaseDate" prefix="rr" icon="calendar"></VIcon>
              <span :class="{ 'is-empty': !bottle.purchaseDate }">{{
                bottle.purchaseDate ? format(bottle.purchaseDate, 'PPP') : ''
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Open Date</label>
              <VIcon v-if="bottle.openDate" prefix="rr" icon="calendar"></VIcon>
              <span :class="{ 'is-empty': !bottle.openDate }">{{
                bottle.openDate ? format(bottle.openDate, 'PPP') : ''
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Finish Date</label>
              <VIcon v-if="bottle.finishDate" prefix="rr" icon="calendar"></VIcon>
              <span :class="{ 'is-empty': !bottle.finishDate }">{{
                bottle.finishDate ? format(bottle.finishDate, 'PPP') : ''
              }}</span>
            </div>
            <div class="col-12">
              <label class="form-label">Notes</label>
              <div class="d-flex align-items-stretch">
                <div
                  v-if="bottle.notes"
                  style="width: 3px"
                  class="bg-stone-700 me-2 my-1 flex-shrink-0 flex-grow-0"
                ></div>
                <div
                  class="fw-normal"
                  style="line-height: 23px"
                  :class="{ 'is-empty': !bottle.notes }"
                >
                  {{ bottle.notes }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- #endregion -->
    </div>
    <div v-if="isLoading">
      <div class="p-5 text-stone-400">Loading...</div>
    </div>
  </ViewBottleLayout>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

label {
  display: block;
  color: map-get($custom-colors, 'amber-500') !important;
}

.img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100px;
  max-width: 50px;
}

.result {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  background-color: map-get($custom-colors, 'stone-800');
}

.spirit-header {
  flex-grow: 1;
}

.result.header {
  background-repeat: no-repeat;
  background-size: cover, cover, 500px;
  background-position:
    center,
    center,
    0% 65%;
  background-color: map-get($custom-colors, 'stone-300');
  border: 0 !important;
}

.result.header h1 {
  font-size: 25px;
  text-shadow: 0 3px 0 black;
}

.result:not(:last-child) {
  border-bottom: 1px solid map-get($custom-colors, 'stone-900');
}

.result:not(:first-child) {
  border-top: 1px solid map-get($custom-colors, 'stone-700');
}

.title {
  display: inline-block;
  font-size: 10px;
  font-weight: 1000;
  text-transform: uppercase;
  color: map-get($custom-colors, 'stone-500');
  margin-bottom: -3px;
  margin-right: 5px;
}

.form-label {
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 1000;
  color: map-get($custom-colors, 'stone-300');
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.error-text {
  position: absolute;
  bottom: -15px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--bs-form-invalid-color);
}

.form-placeholders .placeholder {
  display: block;
}

.form-placeholders .placeholder.label-placeholder {
  width: 45px;
  height: 7px;
  margin-bottom: 7px;
}

.form-placeholders .placeholder.input-placeholder {
  height: 41.58px;
}

select > option {
  background-color: map-get($custom-colors, 'stone-900');
  color: white;
}

select.default-option-selected {
  color: map-get($custom-colors, 'stone-400');
}

.is-empty::after {
  content: 'N/A';
  font-weight: normal;
  color: map-get($custom-colors, 'stone-500');
  font-size: 15px;
  display: block;
  margin-top: 5px;
}

label + i.fi,
.detail-icon {
  margin-right: 8px;
  color: map-get($custom-colors, 'stone-500');
}
</style>

<style lang="scss">
@import '@/assets/colors.scss';
.detail {
  font-weight: normal;
  color: map-get($custom-colors, 'stone-500');
  font-size: 15px;
}
</style>
