import HttpService, { type IHttpService } from '@/services/HttpService'
import Like from '@/models/Like'

const controller = 'likes'

class LikeService {
  private readonly _http: IHttpService

  constructor() {
    this._http = new HttpService(controller)
  }

  public create(like: Like): Promise<Like> {
    return this._http.post<Like, Like>('', like)
  }

  public delete(likeId: string): Promise<void> {
    return this._http.delete(likeId)
  }
}

export default new LikeService()
