import Bottle from '@/models/Bottle'
import FeedItem from '@/models/FeedItem'
import type NewBottleFeedItem from '@/models/NewBottleFeedItem'
import type NewRatingFeedItem from '@/models/NewRatingFeedItem'
import Spirit from '@/models/Spirit'
import HttpService, { type IHttpService } from '@/services/HttpService'

const controller = 'feedItems'

class FeedItemService {
  private readonly _http: IHttpService

  constructor() {
    this._http = new HttpService(controller)
  }

  public async get(id: string): Promise<FeedItem> {
    const feedItemRaw = await this._http.get<FeedItem>(id)
    return this.hydrate(feedItemRaw)
  }

  public async getAllForCurrentUser(): Promise<FeedItem[]> {
    const feedItemsRaw = await this._http.get<FeedItem[]>('')
    return feedItemsRaw.map(this.hydrate)
  }

  public async getAllByUser(userId: string): Promise<FeedItem[]> {
    const feedItemsRaw = await this._http.getWithParams<FeedItem[]>('', { userId })
    return feedItemsRaw.map(this.hydrate)
  }

  hydrate(feedItemRaw: FeedItem) {
    const bottleFeedItem = feedItemRaw as NewBottleFeedItem
    if (bottleFeedItem.bottle != undefined)
      bottleFeedItem.bottle = Bottle.clone(bottleFeedItem.bottle)

    const ratingFeedItem = feedItemRaw as NewRatingFeedItem
    if (ratingFeedItem.rating?.spirit != undefined)
      ratingFeedItem.rating.spirit = Spirit.clone(ratingFeedItem.rating.spirit)

    return feedItemRaw
  }
}

export default new FeedItemService()
