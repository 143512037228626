<script setup lang="ts">
import Rating from '@/models/Rating'
import VIcon from '@/components/VIcon.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

const emit = defineEmits(['delete'])
const { user: currentUser } = storeToRefs(useUserStore())

export interface Props {
  rating: Rating
  showMenu?: boolean
  withNotes?: boolean
  postRoute?: RouteLocationRaw | undefined
}

const props = withDefaults(defineProps<Props>(), {
  showMenu: false,
  withNotes: false
})

const score = computed(() => {
  const avg = (props.rating.aromaScore! + props.rating.tasteScore! + props.rating.finishScore!) / 3
  if (Number.isSafeInteger(avg)) return avg.toString()
  return avg.toFixed(1)
})

const editable = computed(() => currentUser.value?.id === props.rating.userId)
const isNotesVisible = ref(false)
const hasNotes = computed(() => {
  return props.rating.aromaNotes?.trim().length
      || props.rating.tasteNotes?.trim().length
      || props.rating.finishNotes?.trim().length
      || props.rating.summary?.trim().length
})

const routeForHeader = computed(() => {
  if (props.postRoute) return props.postRoute

  return { name: 'spirit', params: { id: props.rating.spirit!.id } }
})

function toggleNotes() {
  isNotesVisible.value = !isNotesVisible.value
}

function deleteRating(rating: Rating) {
  emit('delete', rating)
}
</script>

<template>
  <div class="spirit-card d-block my-2 overflow-hidden bg-stone-800 shadow-sm rounded-2 small">
    <div class="d-flex py-2 border-left-white">
      <div
        style="width: 60px"
        class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
      >
        <div
          class="img-container w-100 h-100"
          :style="{ 'background-image': `url(${rating.spirit!.imgSrc})` }"
        ></div>
      </div>
      <div class="bottle-info-container flex-grow-1">
        <div class="spirit-header d-flex mb-2 align-items-center justify-content-between">
          <h5 class="fw-bolder m-0 p-0" style="flex: 1">
            <router-link
              :to="routeForHeader"
              class="text-primary"
            >
              <span> {{ rating.spirit!.name }}</span>
            </router-link>
          </h5>
          <div class="dropdown" v-if="showMenu">
            <a
              role="button"
              class="d-block text-white"
              data-bs-toggle="dropdown"
              data-bs-popper-config='{"strategy":"fixed"}'
              aria-expanded="false"
            >
              <VIcon
                prefix="br"
                icon="menu-dots-vertical"
                class="d-block fs-5"
                style="transform: translateY(-5px)"
              ></VIcon>
            </a>

            <ul class="dropdown-menu">
              <li v-if="editable">
                <a class="dropdown-item text-danger" role="button" @click="deleteRating(rating)">
                  <VIcon prefix="rr" icon="trash-xmark" class="me-2"></VIcon>
                  <span>Delete</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row gy-2 text-stone-200">
          <div class="col-4">
            <span class="title">Aroma</span>
            <span>{{ rating.aromaScore }}</span>
          </div>
          <div class="col-4">
            <span class="title">Taste</span>
            <span>{{ rating.tasteScore }}</span>
          </div>
          <div class="col-4">
            <span class="title">Finish</span>
            <span>{{ rating.finishScore }}</span>
          </div>
        </div>
      </div>
      <div
        style="width: 70px"
        class="d-flex align-items-center justify-content-center flex-shrink-0"
      >
        <div class="overall-score">{{ score }}</div>
      </div>
    </div>
    <div v-if="withNotes && hasNotes" class="p-2 bg-stone-900">
      <div>
        <a role="button" @click="toggleNotes" class="text-primary">
          <VIcon
            :icon="isNotesVisible ? 'caret-down' : 'caret-right'"
            prefix="sr"
            class="me-1"
          ></VIcon>
          <span class="fw-bold">{{ isNotesVisible ? 'Hide Notes' : 'Show Notes' }}</span>
        </a>
      </div>
      <div v-if="isNotesVisible" class="mt-3">
        <div class="d-flex">
          <span class="title fs-6 text-primary fw-black mb-1">Aroma</span>
          <span class="title fs-6 text-stone-0 fw-black ms-4">{{ rating.aromaScore }}</span>
          <span class="title fs-6 text-stone-400 ms-1">/ 10</span>
        </div>
        <p class="text-stone-300" style="line-height: 20px">
          {{ rating.aromaNotes }}
        </p>

        <div class="d-flex">
          <span class="title fs-6 text-primary fw-black mb-1">Taste</span>
          <span class="title fs-6 text-stone-0 fw-black ms-4">{{ rating.tasteScore }}</span>
          <span class="title fs-6 text-stone-400 ms-1">/ 10</span>
        </div>
        <p class="text-stone-300" style="line-height: 20px">
          {{ rating.tasteNotes }}
        </p>

        <div class="d-flex">
          <span class="title fs-6 text-primary fw-black mb-1">Finish</span>
          <span class="title fs-6 text-stone-0 fw-black ms-4">{{ rating.finishScore }}</span>
          <span class="title fs-6 text-stone-400 ms-1">/ 10</span>
        </div>
        <p class="text-stone-300" style="line-height: 20px">
          {{ rating.finishNotes }}
        </p>

        <div class="d-flex">
          <span class="title fs-6 text-primary fw-black mb-1">Overall</span>
          <span class="title fs-6 text-stone-0 fw-black ms-4">{{ score }}</span>
          <span class="title fs-6 text-stone-400 ms-1">/ 10</span>
        </div>
        <p class="text-stone-300 mb-0" style="line-height: 20px">
          {{ rating.summary }}
        </p>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/colors.scss';
.border-left-white {
  border-left: 5px solid map-get($custom-colors, 'stone-0');
}

.title {
  display: block;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: map-get($custom-colors, 'stone-500');
  margin-bottom: -3px;
}

.spirit-header {
  min-height: 35px;
}

.proof {
  font-size: 9px;
  opacity: 0.5;
  display: block;
  margin-top: -5px;
  text-transform: uppercase;
  font-weight: bold;
}

.img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100px;
  max-width: 50px;
}

.is-empty::after {
  content: 'N/A';
  color: map-get($custom-colors, 'stone-600');
}

.overall-score {
  display: flex;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 1000;
  color: white;
  border: 4px solid map-get($custom-colors, 'stone-700');
  background-color: map-get($custom-colors, 'stone-1000');
  border-radius: 100%;
}

@media only screen and (max-width: 575px) {
  .spirit-header {
    min-height: 0;
  }

  .spirit-header > h5 {
    font-size: 16px;
  }
}
</style>

<style lang="scss">
@import '@/assets/colors.scss';
.h {
  color: map-get($custom-colors, 'amber-300') !important;
  // background-color: map-get($custom-colors, 'primary') !important;
}
</style>
