<script setup lang="ts">
import { FeedItemType } from '@/enums/FeedItemType'
import type FeedItem from '@/models/FeedItem'
import type NewBottleFeedItem from '@/models/NewBottleFeedItem'
import type NewFriendshipFeedItem from '@/models/NewFriendshipFeedItem'
import type User from '@/models/User'
import type UserSummary from '@/models/UserSummary'
import VNewBottleFeedItem from '@/components/VNewBottleFeedItem.vue'
import VNewFriendshipFeedItem from '@/components/VNewFriendshipFeedItem.vue'
import VNewRatingFeedItem from '@/components/VNewRatingFeedItem.vue'
import type NewRatingFeedItem from '@/models/NewRatingFeedItem'

const props = withDefaults(defineProps<{
  feedItems: FeedItem[]
  preferUser?: User | UserSummary
  isLoading?: boolean
}>(), {
  isLoading: false
})

function getComponentForFeedItem(feedItem: FeedItem) {
  if (feedItem.feedItemType === FeedItemType.NewBottle) return VNewBottleFeedItem
  if (feedItem.feedItemType === FeedItemType.NewFriendship) return VNewFriendshipFeedItem
  if (feedItem.feedItemType === FeedItemType.NewRating) return VNewRatingFeedItem
  return ''
}

function getPropsForFeedItem(feedItem: FeedItem) {
  if (feedItem.feedItemType === FeedItemType.NewBottle)
    return { feedItem, bottle: (feedItem as NewBottleFeedItem).bottle }
  if (feedItem.feedItemType === FeedItemType.NewFriendship)
    return { preferUser: props.preferUser, feedItem, friendRequest: (feedItem as NewFriendshipFeedItem).friendRequest }
  if (feedItem.feedItemType === FeedItemType.NewRating)
    return { feedItem, rating: (feedItem as NewRatingFeedItem).rating }
}
</script>

<template>
  <template v-if="!isLoading">
  <component
    v-for="feedItem in feedItems"
    :key="feedItem.id"
    :is="getComponentForFeedItem(feedItem)"
    v-bind="getPropsForFeedItem(feedItem)"
  ></component>

  <div v-if="feedItems.length === 0" class="py-3 text-center">
    <span class="text-stone-400">Looks like there's nothing here!</span>
  </div>
</template>

<template v-if="isLoading">
  <div class="py-3 text-center">
    <span class="text-stone-400">Loading items...</span>
  </div>
</template>
</template>
