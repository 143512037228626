import HttpService, { type IHttpService } from '@/services/HttpService'
import Comment from '@/models/Comment'

const controller = 'comments'

class CommentService {
  private readonly _http: IHttpService

  constructor() {
    this._http = new HttpService(controller)
  }

  public create(comment: Comment): Promise<Comment> {
    return this._http.post<Comment, Comment>('', comment)
  }

  public delete(commentId: string): Promise<void> {
    return this._http.delete(commentId)
  }
}

export default new CommentService()
