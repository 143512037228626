<script setup lang="ts">
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import type Notification from '@/models/Notification'
import notificationService from '@/services/NotificationService'
import { ref } from 'vue'
import VNotification from '@/components/VNotification.vue'

const notifications = ref<Notification[]>([])
const isLoading = ref(false)

function removeNotification(notification: Notification) {
  notifications.value = notifications.value.filter(n => n !== notification)
}

//ON CREATED
;(async () => {
  isLoading.value = true
  try {
    notifications.value = await notificationService.getAllForCurrentUser()
    notificationService.markAllSeenForCurrentUser()
  } catch {
    //noop
  } finally {
    isLoading.value = false
  }
})()
</script>

<template>
  <TitleBarLayout title="Notifications" :back-route="{ name: 'feed' }">
    <div class="p-0">
      <template v-if="!isLoading">
        <VNotification v-for="n in notifications" :key="n.id" :notification="n" @decline-friend-request="removeNotification" @accept-friend-request="removeNotification"></VNotification>
      </template>

      <div v-if="!notifications.length && !isLoading" class="text-center text-stone-400 mt-4">
        You're all caught up!
      </div>

      <div v-if="isLoading" class="text-center text-stone-400 mt-4">Loading notifications...</div>
    </div>
  </TitleBarLayout>
</template>
