import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import User from '@/models/User'
import userService from '@/services/UserService'
import FriendRequest from '@/models/FriendRequest'
import friendRequestService from '@/services/FriendRequestService'
import { useProfileStore } from '@/stores/profile'
import type AuthResponse from '@/models/AuthResponse'

export const useUserStore = defineStore('user', () => {
  const didInitialLoginCheck = ref(false)
  const user = ref<User | undefined>()
  const friendRequests = ref<FriendRequest[]>([])
  const profileStore = useProfileStore();

  const isLoggedIn = computed(() => {
    return Boolean(user.value)
  })

  const isAdmin = computed(() => {
    return isLoggedIn.value === true && user.value?.isAdmin === true
  })

  function setUser(_user: User) {
    user.value = _user
  }

  function setFriendRequests(_friendRequests: FriendRequest[]) {
    friendRequests.value = _friendRequests
  }

  function reset() {
    user.value = undefined
    friendRequests.value = []
    localStorage.removeItem('jwt')
  }

  function upsertFriendRequest(friendRequest: FriendRequest) {
    const idx = friendRequests.value.findIndex((fr) => fr.id === friendRequest.id)
    if (idx >= 0) friendRequests.value[idx] = friendRequest
    else friendRequests.value.push(friendRequest)
  }

  function removeFriendRequestFromState(friendRequestId: string) {
    const idx = friendRequests.value.findIndex((fr) => fr.id === friendRequestId)
    if (idx === -1) return

    friendRequests.value.splice(idx, 1)
  }

  async function sendFriendRequest(recipientId: string) {
    if (
      friendRequests.value.some(
        (fr) => fr.senderId === recipientId || fr.recipientId === recipientId
      )
    )
      return

    const request = await friendRequestService.create(recipientId)
    upsertFriendRequest(request)
  }

  async function acceptFriendRequest(recipientId: string) {
    const existingRequest = friendRequests.value.find(
      (fr) =>
        (fr.senderId === recipientId || fr.recipientId === recipientId) &&
        fr.isAccepted === undefined
    )
    if (!existingRequest?.id) return

    const request = await friendRequestService.accept(existingRequest.id)
    upsertFriendRequest(request)

    profileStore.getFriends()
  }

  async function declineFriendRequest(recipientId: string) {
    // const existingRequest = friendRequests.value.find(
    //   (fr) =>
    //     (fr.senderId === recipientId || fr.recipientId === recipientId) &&
    //     fr.isAccepted === undefined
    // )
    // if (!existingRequest?.id) return

    // const request = await friendRequestService.decline(existingRequest.id)
    // upsertFriendRequest(request)
    await removeFriendRequest(recipientId);
  }

  async function removeFriendRequest(recipientId: string) {
    const existingRequest = friendRequests.value.find(
      (fr) =>
        (fr.senderId === recipientId || fr.recipientId === recipientId)
    )
    if (!existingRequest?.id) return

    await friendRequestService.remove(existingRequest.id)
    removeFriendRequestFromState(existingRequest.id)

    profileStore.getFriends()
  }

  async function init(authResponse: AuthResponse) {
    setUser(authResponse.user)
    localStorage.setItem('jwt', authResponse.jwtToken)

    await getFriendRequests()
  }

  async function login(emailOrHandle: string, password: string) {
    try {
      const authResponse = await userService.login(emailOrHandle, password)
      await init(authResponse)
    } catch {
      reset()
    }
  }

  async function logout() {
    await userService.logout();
    reset()
  }

  async function getFriendRequests() {
    if (!user.value?.id) return;

    const requests = await friendRequestService.getByUserId(user.value.id)
    setFriendRequests(requests)
  }

  async function refresh() {
    try {
      const authResponse = await userService.useRefreshToken()
      await init(authResponse)
    } catch {
      reset()
    }
  }

  async function doInitialLoginCheck() {
    try {
      const authResponse = await userService.useRefreshToken()
      await init(authResponse)
    } catch {
      reset()
    } finally {
      didInitialLoginCheck.value = true
    }
  }

  return {
    user,
    isLoggedIn,
    isAdmin,
    friendRequests,
    didInitialLoginCheck,
    setUser,
    reset,
    login,
    logout,
    refresh,
    doInitialLoginCheck,
    setFriendRequests,
    sendFriendRequest,
    acceptFriendRequest,
    declineFriendRequest,
    removeFriendRequest,
    getFriendRequests
  }
})
