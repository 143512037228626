<script setup lang="ts">
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import VIcon from '@/components/VIcon.vue'

const { user } = storeToRefs(useUserStore())
</script>

<template>
  <TitleBarLayout title="System" :back-route="{ name: 'feed' }" :hide-bottom-nav="true">
    <div class="p-4" v-if="user">
      <h3 class="text-stone-300 text-center">
        Welcome back, <span class="fw-black text-stone-0">{{ user.firstName }}</span
        >.
      </h3>
      <div class="my-4">
        <span class="fw-bold">Actions</span>
        <ul class="system-ul">
          <li class="system-li">
            <router-link :to="{ name: 'bulkImport' }">
              <VIcon icon="file-import" prefix="br"></VIcon>
              <span class="ms-3">Bulk Import</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </TitleBarLayout>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.system-ul {
    margin: 0;
    padding: 0;
}

.system-li {
    padding-left: 10px;
    margin: 0;
    list-style-type: none;
}

.system-li > a {
    display: block;
    padding: 8px;
    font-weight: 1000;
    font-size: 16px;
    color: map-get($custom-colors, 'amber-500');
}
</style>
