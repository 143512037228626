<script setup lang="ts">
import VBottleCard from '@/components/VBottleCard.vue'
import type Bottle from '@/models/Bottle'
import { computed, ref, } from 'vue'
import VInputWrapper from '@/components/VInputWrapper.vue'
import VIcon from '@/components/VIcon.vue'

const SLIM_PREFERENCE_LOCAL_STORAGE_KEY = 'collectionSlimPreference'

const props = withDefaults(
  defineProps<{
    bottles?: Bottle[]
    slim?: boolean
    showMenu?: boolean
  }>(),
  {
    bottles: () => [],
    slim: undefined,
    showMenu: false
  }
)

const emit = defineEmits(['deleteBottle'])

function deleteBottle(bottle: Bottle) {
  emit('deleteBottle', bottle)
}

const bottles = ref(props.bottles)
const slim = ref(
  (() => {
    if (props.slim !== undefined) return props.slim
    const preference = localStorage.getItem(SLIM_PREFERENCE_LOCAL_STORAGE_KEY)
    if (preference === null) return false

    return preference === 'true'
  })()
)

function toggleSlim() {
  if (slim.value === true) {
    localStorage.setItem(SLIM_PREFERENCE_LOCAL_STORAGE_KEY, 'false')
    slim.value = false
  } else {
    localStorage.setItem(SLIM_PREFERENCE_LOCAL_STORAGE_KEY, 'true')
    slim.value = true
  }
}

const filterByType = ref<string | undefined>()
const filterByName = ref<string>('')

const filteredBottles = computed(() => {
  return bottles.value.filter(
    (bottle) =>
      (!filterByType.value || bottle.spirit.type === filterByType.value) &&
      (filterByName.value.trim() === '' ||
        (bottle.spirit.name &&
          bottle.spirit.name.toLowerCase().includes(filterByName.value.toLowerCase())))
  )
})

function clearFilters() {
  filterByType.value = undefined
  filterByName.value = ''
}

const filterTypeOptions = computed(() => {
  const types = bottles.value.reduce(
    (typeCounts, bottle) => {
      if (bottle.spirit.type === undefined) return typeCounts
      typeCounts[bottle.spirit.type] = (typeCounts[bottle.spirit.type] ?? 0) + 1
      return typeCounts
    },
    {} as { [key: string]: number }
  )

  return Object.entries(types).map(([k, v]) => ({ type: k, count: v }))
})
</script>

<template>
  <div>
    <div class="d-flex align-items-center justify-content-between" v-if="bottles?.length">
      <div v-if="showMenu">
        <router-link
          :to="{ name: 'newBottle' }"
          class="btn btn-primary pt-1 pb-0 ps-0 pe-1 d-flex align-items-center"
        >
          <VIcon prefix="br" icon="plus-small" class="fs-3 d-block mt-n2"></VIcon>
          <VIcon prefix="sr" icon="bottle" class="fs-5 ms-n2"></VIcon>
        </router-link>
      </div>
      <div>
        <select
          class="type-select"
          v-model="filterByType"
          :class="{ 'select-placeholder': !filterByType }"
        >
          <option :value="undefined" class="text-white">Filter By Type</option>
          <option
            v-for="option in filterTypeOptions"
            :key="option.type"
            :value="option.type"
            class="text-white"
          >
            {{ option.type }} ({{ option.count }})
          </option>
        </select>
      </div>
      <VInputWrapper prefix="rr" icon="search" :padding="false" style="width: 150px">
        <input type="text" class="borderless" v-model="filterByName" />
      </VInputWrapper>
    </div>

    <VBottleCard
      v-for="bottle in filteredBottles"
      :key="bottle.id"
      :bottle="bottle"
      :slim="true"
      :filterByName="filterByName"
      :showMenu="showMenu"
      @delete="deleteBottle"
    />

    <slot name="empty" v-if="!bottles?.length">
      <div class="p-3 text-center">
        <span class="text-stone-400">
          Looks like this user hasn't added any items to their collection yet.
        </span>
      </div>
    </slot>

    <div v-if="bottles && bottles.length && filteredBottles.length === 0">
      <div class="p-3 text-center">
        <span class="text-stone-400 d-block">The filters returned no results.</span>
        <a role="button" @click="clearFilters">Clear Filters</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/colors.scss';

.select-placeholder {
  color: map-get($custom-colors, 'stone-400');
}

.type-select {
  width: auto;
  display: inline;
  font-size: 13px;
  padding: 0;
  padding-right: 4px;
  border-radius: 0;
  border: none;
  transition: none;
  background-color: map-get($custom-colors, 'stone-900')
}

.type-select option {
  background-color: map-get($custom-colors, 'stone-900')
}

input.borderless {
  border: 0;
  border-bottom: 1px solid map-get($custom-colors, 'stone-500');
  padding: 0 0 0px 24px;
  border-radius: 0 !important;
}
</style>
