import type Bottle from './Bottle'
import type Spirit from './Spirit'
import type User from './User'

export default class Rating {
  id?: string
  created?: Date
  summary?: string
  aromaScore?: number
  aromaNotes?: string
  tasteScore?: number
  tasteNotes?: string
  finishScore?: number
  finishNotes?: string
  imageUrl?: string

  userId: string
  user?: User
  spiritId: string
  spirit?: Spirit
  bottleId?: string
  bottle?: Bottle
  locationId?: string
  //location: Location

  constructor(user: User, spirit: Spirit, bottle?: Bottle) {
    this.userId = user.id!
    this.user = user
    this.spiritId = spirit.id!
    this.spirit = spirit

    if (bottle) {
      this.bottle = bottle
      this.bottleId = bottle.id
    }
  }
}
