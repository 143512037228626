<script setup lang="ts">
import type UserSummary from '@/models/UserSummary'
import VUserAvatar from '@/components/VUserAvatar.vue'
import type User from '@/models/User'
import { useUserStore } from '@/stores/user'

withDefaults(
  defineProps<{
    users: User[] | UserSummary[]
  }>(),
  { users: () => [] }
)

const userStore = useUserStore()

function isSelf(userId: string) {
  return userStore.user?.id === userId
}

function areFriends(userId: string) {
  return userStore.friendRequests.some(
    (fr) =>
      (fr.senderId === userId || fr.recipientId === userId) && fr.isAccepted === true
  )
}

function isRequested(userId: string) {
  return userStore.friendRequests.some(
    (fr) => fr.recipientId === userId && fr.isAccepted !== true
  )
}
</script>

<template>
  <div class="my-n4">
    <div class="d-flex align-items-center my-4" v-for="user in users" :key="user.id">
      <router-link :to="{ name: 'profile', params: { handle: user.handle } }" class="d-flex align-items-center">
        <VUserAvatar :user="user" :size="50"></VUserAvatar>
        <div class="mx-3">
          <span class="d-block fs-6 text-white fw-bold">{{ user.handle }}</span>
          <span class="d-block text-stone-400">{{ user.firstName }} {{ user.lastName }}</span>
        </div>
      </router-link>
      <div v-if="!isSelf(user.id!)" class="ms-auto">
        <button
          v-if="!areFriends(user.id!) && !isRequested(user.id!)"
          class="btn btn-primary py-1 px-2"
          @click="userStore.sendFriendRequest(user.id!)"
        >
          Request
        </button>
        <button
          v-if="areFriends(user.id!)"
          class="btn btn-outline-secondary fw py-1 px-2"
          @click="userStore.removeFriendRequest(user.id!)"
        >
          Unfriend
        </button>
        <span v-if="isRequested(user.id!)" class="btn btn-stone-400 py-1 px-2">Requested</span>
      </div>
    </div>
  </div>
</template>
