<script setup lang="ts">
import { useProfileStore } from '@/stores/profile'
import { storeToRefs } from 'pinia'

defineProps<{
  isNew: boolean
}>()

const { user } = storeToRefs(useProfileStore())
</script>

<template>
  <div class="d-flex flex-column container-xxl" style="height: 100dvh">
    <div class="row">
      <header class="py-3 shadow">
        <router-link
          :to="user ? { name: 'profile', params: { handle: user.handle }, hash: '#collection' } : { name: 'login' }"
          class="d-block text-white"
        >
          <span class="fs-6 text-stone-400">Cancel</span>
        </router-link>
        <h4 class="d-block mx-auto my-0">{{ isNew ? 'Add Bottle' : 'Edit Bottle' }}</h4>
      </header>
    </div>
    <main class="content row">
      <slot></slot>
    </main>
  </div>
</template>

<style scoped lang="scss">
.content {
  flex-grow: 1;
  overflow: auto;
}

header {
  display: flex;
  align-items: center;
}

header > *:first-child {
  flex: 1;
  font-size: 20px;
}

header::after {
  content: '';
  flex: 1;
}
</style>
