<script setup lang="ts">
import Spirit from '@/models/Spirit'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import spiritService from '@/services/SpiritService'
import useVuelidate from '@vuelidate/core'
import { between, helpers, integer, required } from '@vuelidate/validators'
import { SpiritType } from '@/enums/SpiritType'
import SpiritTags from '@/enums/SpiritTags'
import { useToast } from 'vue-toastification'
import VIcon from '@/components/VIcon.vue'
import VInputWrapper from '@/components/VInputWrapper.vue'
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import VButton from '@/components/VButton.vue'

const route = useRoute()
const router = useRouter()
const toast = useToast()

const { user: currentUser } = storeToRefs(useUserStore())
const spirit = ref<Spirit>(new Spirit(currentUser.value!))
const id = ref<string | undefined>(route.params.id as string)
const isNew = computed(() => route.name === 'newSpirit')
const isLoading = ref(true)
const isSaving = ref(false)
const nameInput = ref<HTMLInputElement | null>(null)

const headerStyle = computed(() => {
  let backgroundImageCss =
    'linear-gradient(180deg, #29252470 40%, #292524), linear-gradient(0deg, rgba(0,0,0,0.9), rgba(0,0,0,0.9))'
  if (spirit.value?.image) backgroundImageCss += `, url(${spirit.value.imgSrc})`

  return { 'background-image': backgroundImageCss }
})

function newImage(e: Event) {
  const input = e.target as HTMLInputElement
  if (!input.files) return

  const [file] = input.files
  if (!file) return

  const reader = new FileReader()
  reader.addEventListener(
    'load',
    () => (spirit.value.image = (reader.result ?? '') as string),
    false
  )
  reader.readAsDataURL(file)
}

function toggleTag(tag: string) {
  if (!spirit.value.tags) spirit.value.tags = []
  if (hasTag(tag)) {
    spirit.value.tags = spirit.value.tags.filter((t) => t !== tag)
  } else {
    if (!SpiritTags.includes(tag)) return //invalid tag
    spirit.value.tags.push(tag)
  }
}

function hasTag(tag: string) {
  return spirit.value.tags && spirit.value.tags.includes(tag)
}

const rules = computed(() => ({
  name: { required: helpers.withMessage('Required', required) },
  type: { required: helpers.withMessage('Required', required) },
  abv: {
    between: helpers.withMessage('Between 0-100', between(0, 100))
  },
  abv2: {
    between: helpers.withMessage('Between 0-100', between(0, 100))
  },
  ageInYears: {
    integer: helpers.withMessage('Integer Only', integer),
    between: helpers.withMessage('Between 0-100', between(0, 100))
  }
}))

const v$ = useVuelidate<Spirit>(rules, spirit)

function limitBetween(event: Event, min: number, max: number, decimals: 2) {
  const input = event.target as HTMLInputElement
  const decimalSplit = input.value.split('.')
  const numbersAfterDecimal = decimalSplit.length > 1 ? decimalSplit[1] : ''

  const valueAsNumber = parseFloat(input.value)

  if (valueAsNumber < min) return (input.value = min.toString())
  if (valueAsNumber > max) return (input.value = max.toString())

  if (numbersAfterDecimal.length > decimals) return (input.value = valueAsNumber.toFixed(decimals))
}

async function save(e: Event) {
  e.preventDefault()
  isSaving.value = true

  v$.value.$validate()
  if (v$.value.$error) {
    toast.error('Unable to save. See form for errors.')
    isSaving.value = false
    return false
  }

  try {
    if (spirit.value.id) spirit.value = await spiritService.update(spirit.value)
    else spirit.value = await spiritService.create(spirit.value)

    if (route.query.from?.toString().toLowerCase() === 'addbottle')
      return router.replace({ name: 'newBottle', query: { spiritId: spirit.value.id } })
    else router.replace({ name: 'spirit', params: { id: spirit.value.id } })

    toast.success('Spirit saved!')
  } catch (err) {
    //
  } finally {
    isSaving.value = false
  }
}

//ON CREATED
;(async () => {
  //ADDING NEW SPIRIT
  if (isNew.value) {
    if (route.query.name) spirit.value.name = route.query.name as string
    isLoading.value = false
    return
  }

  //INVALID ID
  if (!id.value) {
    isLoading.value = false
    return router.back()
  }

  //RETRIEVE EXISTING BOTTLE
  try {
    isLoading.value = true
    spirit.value = await spiritService.get(id.value!)
  } catch {
    return router.back()
  } finally {
    isLoading.value = false
  }
})()

onMounted(() => {
  nameInput.value?.focus()
})
</script>

<template>
  <!-- #region EDIT SPIRIT -->
  <TitleBarLayout :title="isNew ? 'Add Spirit' : 'Edit Spirit'">
    <template #back>
      <a @click="$router.back" class="d-block text-white">
        <VIcon prefix="br" icon="angle-left"></VIcon>
      </a>
    </template>

    <div v-if="isLoading" class="p-5 text-center text-stone-400">Loading...</div>

    <div class="p-0" v-if="!isLoading">
      <div class="d-flex justify-content-center position-relative">
        <div
          v-if="!spirit.image"
          class="position-relative flex-grow-1 text-stone-400"
          style="height: 130px"
        >
          <VIcon
            class="position-absolute d-block"
            prefix="rr"
            icon="square"
            style="font-size: 100px; left: 50%; top: 50%; transform: translate(-50%, -50%)"
          ></VIcon>
          <VIcon
            class="position-absolute d-block"
            prefix="br"
            icon="bottle"
            style="font-size: 60px; left: 50%; top: 50%; transform: translate(-50%, -50%)"
          ></VIcon>
        </div>
        <div
          v-if="spirit.image"
          class="result header flex-grow-1 justify-content-center"
          :style="headerStyle"
        >
          <div
            style="width: 150px; height: 150px"
            class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
          >
            <div
              class="img-container w-100 h-100"
              :style="{ 'background-image': `url(${spirit.imgSrc})` }"
            ></div>
          </div>
        </div>
        <div class="image-upload-container">
          <label for="imageUpload"></label>
          <input id="imageUpload" type="file" accept="image/png, image/jpeg" @change="newImage" />
        </div>
      </div>
      <form class="py-3 px-1" @submit="save">
        <div class="container">
          <div class="row gy-3">
            <div class="col-12">
              <label class="form-label" for="spiritName">
                Name<span class="text-primary fw-bold">*</span>
              </label>
              <VInputWrapper prefix="br" icon="bottle">
                <input
                  class="form-control"
                  id="spiritName"
                  v-model="spirit.name"
                  placeholder="Name"
                  maxlength="50"
                  :class="{ 'is-invalid': v$.name.$error }"
                />
                <span v-if="v$.name.$error" class="error-text">{{
                  v$.name.$errors[0].$message
                }}</span>
              </VInputWrapper>
            </div>
            <div class="col-6">
              <label class="form-label" for="spiritType">
                Type<span class="text-primary fw-bold">*</span>
              </label>
              <VInputWrapper prefix="br" icon="bottle">
                <select
                  class="form-control spirit-type"
                  id="spiritType"
                  v-model="spirit.type"
                  :class="{
                    'is-invalid': v$.type.$error,
                    'default-option-selected': spirit.type === undefined
                  }"
                >
                  <option :value="undefined">Select...</option>
                  <option v-for="t in SpiritType" :key="t" :value="t">{{ t }}</option>
                </select>
                <span v-if="v$.type.$error" class="error-text">{{
                  v$.type.$errors[0].$message
                }}</span>
              </VInputWrapper>
            </div>
            <div class="col-6">
              <label class="form-label" for="spiritDistiller">Distiller</label>
              <VInputWrapper prefix="br" icon="warehouse-alt">
                <input
                  class="form-control"
                  id="spiritDistiller"
                  v-model="spirit.distiller"
                  placeholder="Distiller"
                  maxlength="50"
                />
              </VInputWrapper>
            </div>
            <div class="col-6">
              <label class="form-label" for="spiritCountry">Country</label>
              <VInputWrapper prefix="br" icon="flag">
                <input
                  class="form-control"
                  id="spiritCountry"
                  v-model="spirit.country"
                  placeholder="Country"
                  maxlength="50"
                />
              </VInputWrapper>
            </div>
            <div class="col-6">
              <label class="form-label" for="spiritRegion">Region / State</label>
              <VInputWrapper prefix="br" icon="marker">
                <input
                  class="form-control"
                  id="spiritRegion"
                  v-model="spirit.region"
                  placeholder="Region"
                  maxlength="50"
                />
              </VInputWrapper>
            </div>
            <div class="col-12">
              <label class="form-label" for="spiritSubregion">Subregion / City</label>
              <VInputWrapper prefix="br" icon="map-pin">
                <input
                  class="form-control"
                  id="spiritSubregion"
                  v-model="spirit.subregion"
                  placeholder="Subregion"
                  maxlength="50"
                />
              </VInputWrapper>
            </div>

            <div class="col-4">
              <label class="form-label" for="abv">ABV Range</label>
              <VInputWrapper prefix="br" icon="percentage">
                <input
                  class="form-control"
                  ref="abvInput"
                  type="number"
                  id="abv"
                  v-model="spirit.abv"
                  placeholder="50.0"
                  step="0.1"
                  :class="{ 'is-invalid': v$.abv.$error }"
                  @input="limitBetween($event, 0, 100, 2)"
                />
                <span v-if="v$.abv.$error" class="error-text">{{
                  v$.abv.$errors[0].$message
                }}</span>
              </VInputWrapper>
            </div>

            <div class="col-4">
              <label class="form-label" for="abv2">&nbsp;</label>
              <VInputWrapper prefix="br" icon="percentage">
                <input
                  class="form-control"
                  type="number"
                  id="abv2"
                  v-model="spirit.abv2"
                  placeholder="50.0"
                  step="0.1"
                  :class="{ 'is-invalid': v$.abv2.$error }"
                  @input="limitBetween($event, 0, 100, 2)"
                />
                <span v-if="v$.abv2.$error" class="error-text">{{
                  v$.abv2.$errors[0].$message
                }}</span>
              </VInputWrapper>
            </div>

            <div class="col-4">
              <label class="form-label" for="age">Age (Years)</label>
              <VInputWrapper prefix="br" icon="time-past">
                <input
                  class="form-control"
                  type="number"
                  id="age"
                  v-model="spirit.ageInYears"
                  :class="{ 'is-invalid': v$.ageInYears.$error }"
                  placeholder="8"
                />
                <span v-if="v$.ageInYears.$error" class="error-text">{{
                  v$.ageInYears.$errors[0].$message
                }}</span>
              </VInputWrapper>
            </div>

            <div class="col-12">
              <label class="form-label">Tags (Click to select)</label>
              <div class="m-n2">
                <a
                  role="button"
                  @click="toggleTag(tag)"
                  v-for="tag in SpiritTags"
                  :key="tag"
                  class="fs-6 m-2 badge bg-white text-stone-900 py-2 ps-2 pe-3"
                  :class="{ 'opacity-50': !hasTag(tag) }"
                >
                  <VIcon prefix="br" :icon="!hasTag(tag) ? 'bullet' : 'check'"></VIcon>
                  <span class="ms-1">{{ tag }}</span>
                </a>
              </div>
            </div>

            <div class="col-12">
              <label class="form-label" for="description">Description</label>
              <textarea
                id="description"
                v-model="spirit.description"
                maxlength="1000"
                style="height: 150px"
              ></textarea>
            </div>
            <div class="col-12 mt-2">
              <VButton
                type="submit"
                :is-loading="isSaving"
                loading-text="Saving..."
                class="btn btn-primary w-100"
                >Save Spirit</VButton
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </TitleBarLayout>
  <!-- #endregion -->
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.title {
  display: inline-block;
  font-size: 10px;
  font-weight: 1000;
  text-transform: uppercase;
  color: map-get($custom-colors, 'stone-500');
  margin-bottom: -3px;
  margin-right: 5px;
}

.form-label {
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 1000;
  color: map-get($custom-colors, 'stone-300');
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.spirit-type option {
  background-color: map-get($custom-colors, 'stone-900');
  color: white;
}

.error-text {
  position: absolute;
  bottom: -15px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--bs-form-invalid-color);
}
</style>
