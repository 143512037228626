export enum SpiritType {
    Whiskey = 'Whiskey',
    Scotch = 'Scotch',
    Bourbon = 'Bourbon',
    Rye = 'Rye',
    Vodka = 'Vodka',
    Rum = 'Rum',
    Gin = 'Gin',
    Tequila = 'Tequila',
    Cognac = 'Cognac',
    Brandy = 'Brandy',
    Mezcal = 'Mezcal',
    Absinthe = 'Absinthe',
    Moonshine = 'Moonshine'
}
