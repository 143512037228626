import HttpService, { type IHttpService } from '@/services/HttpService'
import User from '@/models/User'
import type AuthResponse from '@/models/AuthResponse'
import type UserSummary from '@/models/UserSummary'
import type CreateAccountViewModel from '@/models/CreateAccountViewModel'
import type EditProfileViewModel from '@/models/EditProfileViewModel'

const controller = 'users'

class UserService {
  private readonly _http: IHttpService

  constructor() {
    this._http = new HttpService(controller)
  }

  public async useRefreshToken(): Promise<AuthResponse> {
    return await this._http.post('refreshTokens/use', {})
  }

  public async register(data: User & { password: string }): Promise<AuthResponse> {
    return await this._http.post('register', data)
  }

  public async login(emailOrHandle: string, password: string): Promise<AuthResponse> {
    return await this._http.post('login', { emailOrHandle, password })
  }

  public async logout(): Promise<void> {
    return await this._http.post('logout', undefined)
  }

  public async get(idOrEmailOrHandle: string): Promise<User> {
    return await this._http.get(idOrEmailOrHandle)
  }

  public async getFriends(userId: string): Promise<UserSummary[]> {
    return await this._http.get(`${userId}/friends`)
  }

  public async validateInvite(inviteId: string): Promise<boolean> {
    return await this._http.post(`validateInvite/${inviteId}`, undefined);
  }

  public async signUp(inviteId: string | undefined, createAccountViewModel: CreateAccountViewModel): Promise<UserSummary> {
    return inviteId
      ? await this._http.post(inviteId, createAccountViewModel)
      : await this._http.post('', createAccountViewModel)
  }

  public async editProfile(editProfileViewModel: EditProfileViewModel): Promise<UserSummary> {
    return await this._http.patch(`profile`, editProfileViewModel);
  }

  public async isHandleAvailable(handle: string): Promise<boolean> {
    return await this._http.get(`${handle}/any`);
  }
}

export default new UserService()
