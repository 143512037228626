import type User from "@/models/User";

export default class Like {
    public id?: string;
    public user: User;
    public feedItemId: string;
    public created?: Date;

    constructor(user: User, feedItemId: string, id?: string, created?: Date) {
      this.user = user;
      this.feedItemId = feedItemId;
      this.created = created;
      this.id = id;
    }
  }
  