<script setup lang="ts">
import VUserAvatar from '@/components/VUserAvatar.vue'
import Comment from '@/models/Comment'
import { intervalToDuration, differenceInDays } from 'date-fns'
import VIcon from '@/components/VIcon.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

withDefaults(
  defineProps<{
    comments: Comment[]
  }>(),
  { comments: () => [] }
)

const emit = defineEmits(['deleteComment'])

const { user } = storeToRefs(useUserStore())

function ago(date: Date) {
  const { years, months, days, hours, minutes } = intervalToDuration({
    start: date,
    end: new Date()
  })

  if (years) return `${years}y ago`
  if (months) return `${Math.round(differenceInDays(new Date(), date) / 7)}w ago`
  if (days) return `${days}d ago`
  if (hours) return `${hours}h ago`
  if (minutes) return `${minutes}m ago`
  return `Now`
}

function deleteComment(commentId: string) {
  emit("deleteComment", commentId);
}
</script>

<template>
  <div class="my-n4">
    <div class="d-flex my-4" v-for="comment in comments" :key="comment.id">
      <div class="me-3">
        <VUserAvatar class="ms-auto" :size="30" :user="comment.user"></VUserAvatar>
      </div>
      <div class="mt-n1">
        <router-link
          :to="{ name: 'profile', params: { handle: comment.user.handle } }"
          class="text-white fw-bold"
        >
          {{ comment.user.handle }}
        </router-link>
        <span class="ms-2">{{ comment.text }}</span>
        <div>
          <span class="d-block small text-stone-400" style="font-size: 11px">
            {{ ago(comment.created!) }}
          </span>
        </div>
      </div>
      <div class="ms-auto" v-if="comment.user.id === user?.id">
        <a role="button" @click="deleteComment(comment.id!)">
          <VIcon prefix="br" icon="trash" class="text-stone-400"></VIcon>
        </a>
      </div>
    </div>
  </div>
</template>
