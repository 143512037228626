<script setup lang="ts">
import VUserAvatar from '@/components/VUserAvatar.vue'
import VIcon from '@/components/VIcon.vue'
import { computed, ref } from 'vue'
import { intervalToDuration } from 'date-fns'
import { differenceInDays } from 'date-fns'
import FeedItem from '@/models/FeedItem'
import type User from '@/models/User'
import type UserSummary from '@/models/UserSummary'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import likeService from '@/services/LikeService'
import Like from '@/models/Like'
import TitleBarLayout from '@/views/layouts/TitleBarLayout.vue'
import VUserList from '@/components/VUserList.vue'
import { useToast } from 'vue-toastification'

const props = withDefaults(
  defineProps<{
    feedItem: FeedItem
    user: User | UserSummary
    withImage?: boolean
  }>(),
  { withImage: true }
)

const toast = useToast()

const { user: currentUser } = storeToRefs(useUserStore())

const likes = ref(props.feedItem.likes)
const comments = ref(props.feedItem.comments)

const liked = computed(() => likes.value.some((l) => l.user.id === currentUser.value?.id))
const justLiked = ref(false)

const commentsCount = computed(() => comments.value.length)
const likesCount = computed(() => likes.value.length)

const showLikes = ref(false)

function tryShowLikes() {
  if (likesCount.value > 0) showLikes.value = true
}

async function likeToggle() {
  if (liked.value === true) {
    //UNLIKE
    const like = likes.value.find((l) => l.user.id === currentUser.value?.id)
    if (!like) return

    justLiked.value = false
    await likeService.delete(like.id!)
    const idx = likes.value.indexOf(like)
    if (idx > -1) likes.value.splice(idx, 1)
  } else {
    //LIKE
    const like = await likeService.create(new Like(currentUser.value!, props.feedItem.id))
    likes.value.push(like)
    justLiked.value = true
  }
}

const ago = computed(() => {
  const date = props.feedItem.created
  const { years, months, days, hours, minutes } = intervalToDuration({
    start: date,
    end: new Date()
  })

  if (years) return `${years}y ago`
  if (months) return `${Math.round(differenceInDays(new Date(), date) / 7)}w ago`
  if (days) return `${days}d ago`
  if (hours) return `${hours}h ago`
  if (minutes) return `${minutes}m ago`
  return `Now`
})

async function share() {
  const url = `https://casknotes.com/posts/${props.feedItem.id}`
  const shareData: ShareData = {
    title: 'Casknotes',
    text: 'Check out this post on Casknotes',
    url
  }

  try {
    await navigator.share(shareData)
  } catch (err) {
    await navigator.clipboard.writeText(`Check out this post on Casknotes: \n\n${url}`);
    toast.success("Copied to clipboard!")
  }
}
</script>

<template>
  <div class="feed-item py-3">
    <div v-if="withImage" class="feed-item-left text-center me-4">
      <VUserAvatar :user="user" class="mx-auto"></VUserAvatar>
      <span class="d-block small text-stone-400 mt-2" style="font-size: 11px">
        {{ ago }}
      </span>
    </div>

    <div class="feed-item-content">
      <div class="feed-item-header">
        <div class="feed-item-summary">
          <slot name="header"></slot>
        </div>
        <div class="feed-item-header-date" v-if="!withImage">
          <span class="d-block small text-stone-400">
            {{ ago }}
          </span>
        </div>
      </div>

      <div class="feed-item-body my-3">
        <slot name="body"></slot>
      </div>

      <div class="feed-item-footer">
        <div class="feed-item-actions">
          <a role="button" class="me-4" :class="{ 'just-liked': justLiked }" @click="likeToggle">
            <VIcon
              :prefix="liked ? 'sr' : 'rr'"
              icon="glass-cheers"
              :class="{ 'text-primary': liked }"
            ></VIcon>
          </a>
          <router-link :to="{ name: 'post', params: { id: feedItem.id } }"
            ><VIcon prefix="rr" icon="comment-alt-middle"></VIcon
          ></router-link>
          <a role="button" class="ms-4" @click="share">
            <VIcon prefix="rr" icon="share"></VIcon>
          </a>
        </div>
        <div class="feed-item-stats">
          <a role="button" @click="tryShowLikes" :class="{ 'text-stone-500': likesCount < 1, 'text-stone-400': likesCount >= 1 }">
            <span>{{ likesCount }}</span>
            <span>{{ likesCount === 1 ? ' like' : ' likes' }}</span>
          </a>
          <VIcon prefix="ss" icon="bullet" class="mx-1 text-stone-700"></VIcon>
          <router-link class="text-stone-400" :to="{ name: 'post', params: { id: feedItem.id } }">
            <span>{{ commentsCount }}</span>
            <span>{{ commentsCount === 1 ? ' comment' : ' comments' }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <TitleBarLayout v-if="showLikes" title="Likes" class="overlay" :hide-bottom-nav="true">
      <template #back>
        <a role="button" @click="showLikes = false" class="d-block text-white" style="flex: 1">
          <VIcon prefix="br" icon="angle-left"></VIcon>
        </a>
      </template>

      <div class="py-3">
        <VUserList :users="likes.map((l) => l.user)" class="my-0"></VUserList>
      </div>
    </TitleBarLayout>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: map-get($custom-colors, 'stone-900');
  z-index: 10;
}

.feed-item {
  display: flex;
  border-bottom: 1px solid map-get($custom-colors, 'stone-800');
}

.feed-item:not(.embedded):first-child {
  padding-top: 0 !important;
}

.feed-item-left {
  text-align: center;
  width: 45px;
  flex-shrink: 0;
  flex-grow: 0;
}

.feed-item-content {
  flex-grow: 1;
}

.feed-item-header {
  display: flex;
  align-items: center;
}

.feed-item-summary {
  flex-grow: 1;
}

.feed-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feed-item-header-date {
  white-space: nowrap;
}

.feed-item-stats {
  color: map-get($custom-colors, 'stone-400');
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.3px;
}

.feed-item-stats a:hover {
  text-decoration: underline !important;
}

.feed-item-actions {
  display: flex;
  font-size: 20px;
}

.feed-item-actions > a {
  color: map-get($custom-colors, 'stone-400');
}

.feed-item-actions > a:hover {
  color: map-get($custom-colors, 'stone-0');
}

.just-liked {
  transform-origin: center;
  animation-duration: 0.5s;
  animation-name: pulse;
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

@keyframes pulse {
  0% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  20% {
    animation-timing-function: ease-in;
    transform: scale(1.3) rotate(5deg);
  }

  40% {
    transform: scale(0.93) rotate(-10deg);
  }

  60% {
    animation-timing-function: ease-in;
    transform: scale(1.1) rotate(2deg);
  }

  80% {
    animation-timing-function: ease-out;
    transform: scale(0.98) rotate(-2deg);
  }

  100% {
    transform: scale(1);
  }
}
</style>
