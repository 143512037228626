export function handle(value?: string) {
  if (!value) return false
  if (value.trim().length === 0) return false
  if (value.length > 25) return false

  const regex = new RegExp('^[a-zA-Z0-9_.]*$')
  if (!regex.test(value)) return false

  return true
}

export function password(value?: string) {
  if (!value) return false
  if (value.trim().length === 0) return false
  if (value.length > 25 || value.length < 8) return false

  return true
}
