import HttpService, { type IHttpService } from '@/services/HttpService'
import Notification from '@/models/Notification'

const controller = 'notifications'

class NotificationService {
  private readonly _http: IHttpService

  constructor() {
    this._http = new HttpService(controller)
  }

  public async getAllForCurrentUser(): Promise<Notification[]> {
    return this._http.get('')
  }

  public async markAllSeenForCurrentUser(): Promise<void> {
    return this._http.post('seen', undefined)
  }
}

export default new NotificationService()
