import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import userService from '@/services/UserService'
import User from '@/models/User'
import type Bottle from '@/models/Bottle'
import bottleService from '@/services/BottleService'
import type FeedItem from '@/models/FeedItem'
import type UserSummary from '@/models/UserSummary'
import { useUserStore } from '@/stores/user'
import feedItemService from '@/services/FeedItemService'
import ratingService from '@/services/RatingService'
import type Rating from '@/models/Rating'

export const useProfileStore = defineStore('profile', () => {
  const user = ref<User | undefined>()
  const collection = ref<Bottle[] | undefined>()
  const feed = ref<FeedItem[] | undefined>()
  const friends = ref<UserSummary[] | undefined>()
  const ratings = ref<Rating[] | undefined>()

  const userStore = useUserStore()
  const isSelf = computed(() => user.value && userStore.user && user.value.id === userStore.user.id)

  function setUser(_user: User) {
    user.value = _user
  }

  function setCollection(_collection: Bottle[]) {
    collection.value = _collection
  }

  function setFeed(_feed: FeedItem[]) {
    feed.value = _feed
  }

  function setFriends(_friends: UserSummary[]) {
    friends.value = _friends
  }

  function setRatings(_ratings: Rating[]) {
    ratings.value = _ratings
  }

  function setFullProfile(_user: User, _feed: FeedItem[], _friends: UserSummary[], _collection: Bottle[], _ratings: Rating[]) {
    setUser(_user);
    setFeed(_feed)
    setFriends(_friends)
    setCollection(_collection)
    setRatings(_ratings)
  }

  function upsertIntoCollection(bottle: Bottle) {
    if (!collection.value) return

    const existingIndex = collection.value.findIndex((b) => b.id === bottle.id)
    if (existingIndex >= 0) return (collection.value[existingIndex] = bottle)

    collection.value.push(bottle)
  }

  function reset() {
    user.value = undefined
    collection.value = undefined
    feed.value = undefined
    friends.value = undefined
    ratings.value = undefined
  }

  async function getUser(idOrEmailOrHandle: string) {
    return await userService.get(idOrEmailOrHandle)
  }

  async function getFriends(userId?: string, set = true) {
    const uId = userId ?? user.value?.id;
    if (!uId) return
    const friends = await userService.getFriends(uId)

    if (set) setFriends(friends)

    return friends
  }

  async function getCollection(userId?: string, set = true) {
    const uId = userId ?? user.value?.id;
    if (!uId) return
    const collection = await bottleService.getByUser(uId)

    if (set) setCollection(collection)

    return collection;
  }

  async function getRatings(userId?: string, set = true) {
    const uId = userId ?? user.value?.id;
    if (!uId) return
    const ratings = await ratingService.getWithFilters({ userId: uId })

    if (set) setRatings(ratings)

    return ratings;
  }

  async function getFeed(userId?: string, set = true) {
    const uId = userId ?? user.value?.id;
    if (!uId) return
    const feedItems = await feedItemService.getAllByUser(uId)

    if (set) setFeed(feedItems)

    return feedItems;
  }

  async function getFullProfile(idOrEmailOrHandle: string) {
    const user = await getUser(idOrEmailOrHandle)
    const feed = await getFeed(user.id, false)
    const friends = await getFriends(user.id, false)
    const collection = await getCollection(user.id, false)
    const ratings = await getRatings(user.id, false)
    setFullProfile(user, feed ?? [], friends ?? [], collection ?? [], ratings ?? []);
  }

  async function deleteBottle(bottle: Bottle) {
    if (!bottle.id || !collection.value) return;
    const idx = collection.value.findIndex((b) => b.id === bottle.id)
    if (idx >= 0) collection.value.splice(idx, 1)

    await bottleService.delete(bottle.id);
  }

  const friendRequestForCurrentUser = computed(() => {
    if (!user.value?.id || !userStore.user) return undefined
    return userStore.friendRequests.find(
      (fr) => fr.senderId === user.value!.id || fr.recipientId === user.value!.id
    )
  })

  const isFriendsWithCurrentUser = computed(() => {
    if (!friendRequestForCurrentUser.value) return false
    return friendRequestForCurrentUser.value.isAccepted === true
  })

  const currentUserNeedsToRespond = computed(() => {
    if (!friendRequestForCurrentUser.value) return false
    return (
      friendRequestForCurrentUser.value.recipientId === userStore.user!.id &&
      friendRequestForCurrentUser.value.isAccepted === undefined
    )
  })

  const currentUserAwaitingResponse = computed(() => {
    if (!friendRequestForCurrentUser.value) return false
    return (
      friendRequestForCurrentUser.value.senderId === userStore.user!.id &&
      friendRequestForCurrentUser.value.isAccepted === undefined
    )
  })

  return {
    user,
    collection,
    feed,
    friends,
    ratings,
    isSelf,
    friendRequestForCurrentUser,
    isFriendsWithCurrentUser,
    currentUserNeedsToRespond,
    currentUserAwaitingResponse,
    reset,
    getFullProfile,
    upsertIntoCollection,
    getFriends,
    getRatings,
    deleteBottle,
    getFeed
  }
})
