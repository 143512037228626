<script setup lang="ts">
import { storeToRefs } from 'pinia'
import Bottle from '@/models/Bottle'
import { computed, onMounted, ref, watch, reactive } from 'vue'
import type User from '@/models/User'
import bottleService from '@/services/BottleService'
import { useRoute, useRouter } from 'vue-router'
import EditBottleLayout from '@/views/layouts/EditBottleLayout.vue'
import { useUserStore } from '@/stores/user'
import { between, helpers, integer, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import Spirit from '@/models/Spirit'
import spiritService from '@/services/SpiritService'
import VSearch from '@/components/VSearch.vue'
import VIcon from '@/components/VIcon.vue'
import VInputWrapper from '@/components/VInputWrapper.vue'
import { nextTick } from 'vue'
import { addDays, format, parse } from 'date-fns'
import VButton from '@/components/VButton.vue'
import { useToast } from 'vue-toastification'
import { useProfileStore } from '@/stores/profile'

const { user } = storeToRefs(useUserStore())
const profileStore = useProfileStore()
const route = useRoute()
const router = useRouter()
const toast = useToast()

const id = ref<string | undefined>(route.params.id as string)
const bottle = ref<Bottle>(new Bottle(user.value as User))
const spirit = computed(() => bottle.value.spirit)
const isNew = computed(() => route.name === 'newBottle')
const spiritSelected = ref(false)
const abvInput = ref<HTMLInputElement | null>(null)
const isSaving = ref(false)
const isLoading = ref(true)
const isEditingSpirit = ref(false)
const stringDates = reactive({
  distillationDate: '',
  bottlingDate: '',
  purchaseDate: '',
  openDate: '',
  finishDate: ''
})

function limitLength(event: Event) {
  const input = event.target as HTMLInputElement
  if (input.value.length > input.maxLength) input.value = input.value.slice(0, input.maxLength)
}

function limitBetween(event: Event, min: number, max: number, decimals: 2) {
  const input = event.target as HTMLInputElement
  const decimalSplit = input.value.split('.')
  const numbersAfterDecimal = decimalSplit.length > 1 ? decimalSplit[1] : ''

  const valueAsNumber = parseFloat(input.value)

  if (valueAsNumber < min) return (input.value = min.toString())
  if (valueAsNumber > max) return (input.value = max.toString())

  if (numbersAfterDecimal.length > decimals) return (input.value = valueAsNumber.toFixed(decimals))
}

function redirectToProfile(hash?: string) {
  return router.push({
    name: 'profile',
    params: { handle: user.value?.handle },
    hash: hash ? `#${hash}` : ''
  })
}

async function searchSpirit(query: string) {
  return await spiritService.search(query)
}

function tryFormat(date: Date | undefined) {
  if (!date) return ''
  return format(date, 'yyyy-MM-dd')
}

function tryParse(dateString: string) {
  if (dateString === '') return undefined
  return parse(dateString, 'yyyy-MM-dd', new Date())
}

function setStringDates() {
  if (!bottle.value) return

  stringDates.distillationDate = tryFormat(bottle.value.distillationDate)
  stringDates.bottlingDate = tryFormat(bottle.value.bottlingDate)
  stringDates.purchaseDate = tryFormat(bottle.value.purchaseDate)
  stringDates.openDate = tryFormat(bottle.value.openDate)
  stringDates.finishDate = tryFormat(bottle.value.finishDate)
}

watch(stringDates, (obj) => {
  bottle.value.distillationDate = tryParse(obj.distillationDate)
  bottle.value.bottlingDate = tryParse(obj.bottlingDate)
  bottle.value.purchaseDate = tryParse(obj.purchaseDate)
  bottle.value.openDate = tryParse(obj.openDate)
  bottle.value.finishDate = tryParse(obj.finishDate)
})

const dateValidator = (val: Date) => {
  if (val === undefined) return true
  if (isNaN(val.getTime())) return false
  return val >= new Date('1900-01-01') && val < addDays(new Date(), 1)
}

const bottleRules = computed(() => ({
  abv: {
    required: helpers.withMessage('Required', required),
    between: helpers.withMessage('Between 0-100', between(0, 100))
  },
  sizeInMl: {
    required: helpers.withMessage('Required', required),
    integer,
    between: helpers.withMessage('Between 50-2000', between(50, 2000))
  },
  ageYears: {
    integer: helpers.withMessage('Integer Only', integer),
    between: helpers.withMessage('Between 0-100', between(0, 100))
  },
  ageMonths: {
    integer: helpers.withMessage('Integer Only', integer),
    between: helpers.withMessage('Between 0-11', between(0, 11))
  },
  batch: {},
  seller: {},
  pick: {},
  pricePaid: { between: helpers.withMessage('Between 0-10000', between(0, 10000)) },
  distillationDate: { between: helpers.withMessage('Invalid date', dateValidator) },
  bottlingDate: { between: helpers.withMessage('Invalid date', dateValidator) },
  purchaseDate: { between: helpers.withMessage('Invalid date', dateValidator) },
  openDate: { between: helpers.withMessage('Invalid date', dateValidator) },
  finishDate: { between: helpers.withMessage('Invalid date', dateValidator) },
  remaining: {
    integer: helpers.withMessage('Integer Only', integer),
    between: helpers.withMessage('Between 0-100', between(0, 100))
  }
}))

const v$ = useVuelidate<Bottle>(bottleRules, bottle)

async function selectSpirit(spirit: Spirit) {
  bottle.value.spirit = spirit
  spiritSelected.value = true

  await nextTick()
  abvInput.value?.focus()
}

async function addSpirit(name: string) {
  return router.push({ name: 'newSpirit', query: { name, from: 'addBottle' } })
}

const headerStyle = computed(() => {
  let backgroundImageCss =
    'linear-gradient(180deg, #29252470 40%, #292524), linear-gradient(0deg, rgba(0,0,0,0.9), rgba(0,0,0,0.9))'
  if (spirit.value.image) backgroundImageCss += `, url(${spirit.value.imgSrc})`

  return { 'background-image': backgroundImageCss }
})

async function saveBottle(e: Event) {
  isSaving.value = true
  e.preventDefault()

  v$.value.$validate()
  if (v$.value.$error) {
    isSaving.value = false
    toast.error('Unable to save. See form for errors.')
    return false
  }

  try {
    Object.entries(bottle.value).forEach(([k, v]) => {
      if (v === '') (bottle.value as { [key: string]: any | undefined })[k] = undefined
    })

    if (bottle.value.id) bottle.value = await bottleService.update(bottle.value)
    else bottle.value = await bottleService.create(bottle.value)

    profileStore.upsertIntoCollection(bottle.value)
    redirectToProfile('collection')
    toast.success('Bottle saved!')
  } catch (err) {
    console.log(err)
  } finally {
    isSaving.value = false
  }
}

//ON CREATED
;(async () => {
  //ADDING NEW BOTTLE
  if (isNew.value) {
    if (route.query.spiritId) {
      const spirit = await spiritService.get(route.query.spiritId as string)
      isLoading.value = false
      selectSpirit(spirit)
    }
    isLoading.value = false
    return
  }

  //INVALID ID
  if (!id.value) {
    isLoading.value = false
    return redirectToProfile()
  }

  //RETRIEVE EXISTING BOTTLE
  try {
    bottle.value = await bottleService.get(id.value!)
    spiritSelected.value = true
    setStringDates()

    await nextTick()
    abvInput.value?.focus()
  } catch {
    return redirectToProfile()
  } finally {
    isLoading.value = false
  }
})()

onMounted(async () => {
  abvInput.value?.focus()
})
</script>

<template>
  <EditBottleLayout :is-new="isNew">
    <div v-if="!isLoading" class="p-0">
      <!-- #region SEARCH -->
      <div>
        <VSearch
          v-if="!spiritSelected"
          search-property="name"
          :search-function="searchSpirit"
          key-property="id"
          placeholder="Search by name..."
          :focusOnLoad="true"
        >
          <template #result-item="{ item }">
            <a role="button" class="result" @click="selectSpirit(item)">
              <div
                style="width: 70px; height: 70px"
                class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
              >
                <div
                  class="img-container w-100 h-100"
                  :style="{ 'background-image': `url(${item.imgSrc})` }"
                ></div>
              </div>
              <div class="spirit-header ms-2">
                <h5 class="my-0 text-primary fw-bold">{{ item.name }}</h5>
                <span class="d-block small text-stone-300">{{ item.type }}</span>
              </div>
            </a>
          </template>
          <template #add-item="{ query }">
            <a role="button" class="result" @click="addSpirit(query)">
              <div
                style="width: 70px; height: 70px"
                class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
              >
                <VIcon prefix="br" icon="plus-small" style="font-size: 50px"></VIcon>
              </div>
              <div class="spirit-header ms-2">
                <h5 class="my-0 text-stone-300">
                  Add <i class="text-white">&quot;{{ query }}&quot;</i>
                </h5>
              </div>
            </a>
          </template>
          <template #no-query>
            <div class="text-center text-stone-400 p-5">
              <span class="d-block">Use the input above to find the bottle you'd like to add.</span>
            </div>
          </template>
        </VSearch>
      </div>
      <!-- #endregion -->

      <!-- #region EDIT BOTTLE -->
      <template v-if="spiritSelected && !isEditingSpirit">
        <div class="bg-stone-800">
          <div class="result header" :style="headerStyle">
            <div
              style="width: 100px; height: 100px"
              class="d-flex align-items-center justify-content-center flex-shrink-0 py-2"
            >
              <div
                class="img-container w-100 h-100"
                :style="{ 'background-image': `url(${spirit.imgSrc})` }"
              ></div>
            </div>
            <div class="spirit-header ms-2 text-end me-4">
              <h1 class="my-0 fw-black text-primary">
                {{ spirit.name }}
              </h1>
              <div style="font-size: 13px">
                <div class="d-flex justify-content-end">
                  <div>
                    <span>{{ spirit.type }}</span>
                  </div>
                  <template v-if="spirit.distiller">
                    <div class="mx-1 title" style="margin-top: 2px">
                      <VIcon prefix="sr" icon="bullet"></VIcon>
                    </div>
                    <div>
                      <span>{{ spirit.distiller }}</span>
                    </div>
                  </template>
                  <template v-if="spirit.region">
                    <div class="mx-1 title" style="margin-top: 2px">
                      <VIcon prefix="sr" icon="bullet"></VIcon>
                    </div>
                    <div>
                      <span>{{ spirit.region }}</span>
                    </div>
                  </template>
                </div>
              </div>
              <div class="mt-2 mx-n1">
                <span
                  class="badge bg-secondary text-stone-900 fw-black text-uppercase opacity-75 mx-1"
                  v-for="tag in spirit.tags"
                  :key="tag"
                  >{{ tag }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <form class="py-3 px-1" @submit="saveBottle" novalidate>
          <div class="container">
            <div class="row gy-3">
              <div class="col-4">
                <label class="form-label" for="abv"
                  >ABV <span class="text-primary fw-bold">*</span></label
                >
                <VInputWrapper prefix="br" icon="percentage">
                  <input
                    class="form-control"
                    ref="abvInput"
                    type="number"
                    id="abv"
                    v-model="bottle.abv"
                    placeholder="50.0"
                    step="0.1"
                    :class="{ 'is-invalid': v$.abv.$error }"
                    @input="limitBetween($event, 0, 100, 2)"
                  />
                  <span v-if="v$.abv.$error" class="error-text">{{
                    v$.abv.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-4">
                <label class="form-label" for="sizeInMl"
                  >Size (ml) <span class="text-primary fw-bold">*</span></label
                >
                <VInputWrapper prefix="br" icon="bottle">
                  <input
                    class="form-control"
                    type="number"
                    id="sizeInMl"
                    v-model="bottle.sizeInMl"
                    placeholder="750"
                    :class="{ 'is-invalid': v$.sizeInMl.$error }"
                    maxlength="4"
                    inputmode="numeric"
                  />
                  <span v-if="v$.sizeInMl.$error" class="error-text">{{
                    v$.sizeInMl.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-4">
                <label class="form-label" for="batch">Batch</label>
                <VInputWrapper prefix="br" icon="label">
                  <input
                    class="form-control"
                    id="batch"
                    v-model="bottle.batch"
                    placeholder="C928"
                    :class="{ 'is-invalid': v$.batch.$error }"
                    maxlength="50"
                  />
                  <span v-if="v$.batch.$error" class="error-text">{{
                    v$.batch.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-8">
                <label class="form-label" for="remaining">Age</label>
                <VInputWrapper prefix="br" icon="time-past">
                  <div class="input d-flex">
                    <div class="d-flex">
                      <input
                        type="number"
                        v-model="bottle.ageYears"
                        class="form-control d-block"
                        style="width: 25px"
                        maxlength="2"
                        @input="limitLength($event)"
                      />
                      <label class="text-stone-500">years</label>
                    </div>
                    <div class="d-flex ms-4">
                      <input
                        type="number"
                        v-model="bottle.ageMonths"
                        class="d-block form-control"
                        style="width: 25px"
                        max="11"
                        maxlength="2"
                        @input="limitLength($event)"
                      />
                      <label class="text-stone-500">months</label>
                    </div>
                  </div>
                </VInputWrapper>
              </div>

              <div class="col-4">
                <label class="form-label" for="remaining">% Remaining</label>
                <VInputWrapper prefix="br" icon="humidity">
                  <input
                    class="form-control"
                    type="number"
                    id="remaining"
                    v-model="bottle.remaining"
                    placeholder="50"
                    :class="{ 'is-invalid': v$.remaining.$error }"
                  />
                  <span v-if="v$.remaining.$error" class="error-text">{{
                    v$.remaining.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-8">
                <label class="form-label" for="seller">Seller</label>
                <VInputWrapper prefix="br" icon="shopping-cart">
                  <input
                    class="form-control"
                    id="seller"
                    v-model="bottle.seller"
                    placeholder="Local Spirits"
                    :class="{ 'is-invalid': v$.seller.$error }"
                  />
                  <span v-if="v$.seller.$error" class="error-text">{{
                    v$.seller.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-4">
                <label class="form-label" for="pricePaid">Paid</label>
                <VInputWrapper prefix="br" icon="dollar">
                  <input
                    class="form-control"
                    type="number"
                    id="pricePaid"
                    v-model="bottle.pricePaid"
                    placeholder="50.00"
                    :class="{ 'is-invalid': v$.pricePaid.$error }"
                  />
                  <span v-if="v$.pricePaid.$error" class="error-text">{{
                    v$.pricePaid.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-6">
                <label class="form-label" for="pick">Barrel Pick</label>
                <VInputWrapper prefix="br" icon="star">
                  <input
                    class="form-control"
                    id="pick"
                    v-model="bottle.pick"
                    placeholder="County Favorite"
                    :class="{ 'is-invalid': v$.pick.$error }"
                  />
                  <span v-if="v$.pick.$error" class="error-text">{{
                    v$.pick.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-6">
                <label class="form-label" for="distillationDate">Distillation Date</label>
                <VInputWrapper prefix="br" icon="calendar">
                  <input
                    class="form-control"
                    type="date"
                    id="distillationDate"
                    v-model="stringDates.distillationDate"
                    :class="{
                      'date-input--has-value': bottle.distillationDate,
                      'is-invalid': v$.distillationDate.$error
                    }"
                  />
                  <span v-if="v$.distillationDate.$error" class="error-text">{{
                    v$.distillationDate.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-6">
                <label class="form-label" for="bottlingDate">Bottling Date</label>
                <VInputWrapper prefix="br" icon="calendar">
                  <input
                    class="form-control"
                    type="date"
                    id="bottlingDate"
                    v-model="stringDates.bottlingDate"
                    :class="{
                      'date-input--has-value': bottle.bottlingDate,
                      'is-invalid': v$.bottlingDate.$error
                    }"
                  />
                  <span v-if="v$.bottlingDate.$error" class="error-text">{{
                    v$.bottlingDate.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-6">
                <label class="form-label" for="purchaseDate">Purchase Date</label>
                <VInputWrapper prefix="br" icon="calendar">
                  <input
                    class="form-control"
                    type="date"
                    id="purchaseDate"
                    v-model="stringDates.purchaseDate"
                    :class="{
                      'date-input--has-value': bottle.purchaseDate,
                      'is-invalid': v$.purchaseDate.$error
                    }"
                  />
                  <span v-if="v$.purchaseDate.$error" class="error-text">{{
                    v$.purchaseDate.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-6">
                <label class="form-label" for="openDate">Open Date</label>
                <VInputWrapper prefix="br" icon="calendar">
                  <input
                    class="form-control"
                    type="date"
                    id="openDate"
                    v-model="stringDates.openDate"
                    :class="{
                      'date-input--has-value': bottle.openDate,
                      'is-invalid': v$.openDate.$error
                    }"
                  />
                  <span v-if="v$.openDate.$error" class="error-text">{{
                    v$.openDate.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-6">
                <label class="form-label" for="finishDate">Finish Date</label>
                <VInputWrapper prefix="br" icon="calendar">
                  <input
                    class="form-control"
                    type="date"
                    id="finishDate"
                    v-model="stringDates.finishDate"
                    :class="{
                      'date-input--has-value': bottle.finishDate,
                      'is-invalid': v$.finishDate.$error
                    }"
                  />
                  <span v-if="v$.finishDate.$error" class="error-text">{{
                    v$.finishDate.$errors[0].$message
                  }}</span>
                </VInputWrapper>
              </div>

              <div class="col-12">
                <label class="form-label" for="notes">Notes</label>
                <textarea id="notes" v-model="bottle.notes" maxlength="500"></textarea>
              </div>

              <div class="col-12">
                <VButton
                  type="submit"
                  :is-loading="isSaving"
                  loading-text="Saving..."
                  class="btn btn-primary w-100"
                  >Save Bottle</VButton
                >
              </div>
            </div>
          </div>
        </form>
      </template>
      <!-- #endregion -->
    </div>

    <!-- #region SKELETON -->
    <div v-if="isLoading" class="px-0 m-0">
      <template>
        <div
          class="bg-stone-800 d-flex justify-content-between px-2 align-items-center placeholder-glow"
          style="height: 120px"
        >
          <div class="ms-3" style="width: 50px; height: 80px">
            <span class="placeholder h-100 w-100"></span>
          </div>

          <div class="flex-grow-1 text-end">
            <span class="ms-auto placeholder d-block" style="width: 50%; height: 30px"></span>
            <span class="ms-auto placeholder d-block my-1" style="width: 60%; height: 16px"></span>
            <div class="mt-2">
              <span class="ms-auto placeholder me-2" style="width: 20%; height: 16px"></span>
              <span class="ms-auto placeholder" style="width: 20%; height: 16px"></span>
            </div>
          </div>
        </div>

        <form class="py-3 px-1">
          <div class="container">
            <div class="row gy-3 form-placeholders placeholder-glow">
              <div class="col-6">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-6">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-8">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-4">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-8">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-4">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-6">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-6">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-6">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-6">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-6">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-6">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder">test</span>
              </div>
              <div class="col-12">
                <span class="placeholder label-placeholder"></span>
                <span class="placeholder input-placeholder" style="height: 64px">test</span>
              </div>
              <div class="col-12 mt-4">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder"></a>
              </div>
            </div>
          </div>
        </form>
      </template>
    </div>
    <!-- #endregion -->
  </EditBottleLayout>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100px;
  max-width: 50px;
}

.result {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  background-color: map-get($custom-colors, 'stone-800');
}

.spirit-header {
  flex-grow: 1;
}

.result.header {
  background-repeat: no-repeat;
  background-size: cover, cover, 500px;
  background-position:
    center,
    center,
    0% 65%;
  background-color: map-get($custom-colors, 'stone-300');
  border: 0 !important;
  position: relative;
}

.image-upload-container > label {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.image-upload-container input[type='file'] {
  display: none;
}

.result.header h1 {
  font-size: 25px;
  text-shadow: 0 3px 0 black;
}

.result:not(:last-child) {
  border-bottom: 1px solid map-get($custom-colors, 'stone-900');
}

.result:not(:first-child) {
  border-top: 1px solid map-get($custom-colors, 'stone-700');
}

.title {
  display: inline-block;
  font-size: 10px;
  font-weight: 1000;
  text-transform: uppercase;
  color: map-get($custom-colors, 'stone-500');
  margin-bottom: -3px;
  margin-right: 5px;
}

.form-label {
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 1000;
  color: map-get($custom-colors, 'stone-300');
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.error-text {
  position: absolute;
  bottom: -15px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--bs-form-invalid-color);
}

.form-placeholders .placeholder {
  display: block;
}

.form-placeholders .placeholder.label-placeholder {
  width: 45px;
  height: 7px;
  margin-bottom: 7px;
}

.form-placeholders .placeholder.input-placeholder {
  height: 41.58px;
}

select > option {
  background-color: map-get($custom-colors, 'stone-900');
  color: white;
}

select.default-option-selected {
  color: map-get($custom-colors, 'stone-400');
}
</style>
