<script setup lang="ts">
import VUserAvatar from '@/components/VUserAvatar.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import VIcon from '@/components/VIcon.vue'
import { ref } from 'vue'
import notificationService from '@/services/NotificationService'

const { user } = storeToRefs(useUserStore())

const notificationCount = ref(0)

//ON CREATED
;(async () => {
  const notifications = await notificationService.getAllForCurrentUser()
  notificationCount.value = notifications.filter(n => !n.seen).length
})()
</script>

<template>
  <div class="mt-auto d-flex justify-content-center align-items-center shadow bg-stone-900 p-0">
    <router-link :to="{ name: 'feed' }" role="button" class="nav-btn">
      <VIcon prefix="rr" icon="home"></VIcon>
    </router-link>

    <router-link :to="{ name: 'search' }" role="button" class="nav-btn">
      <VIcon prefix="rr" icon="search"></VIcon>
    </router-link>

    <router-link :to="{ name: 'notifications' }" role="button" class="nav-btn position-relative">
      <VIcon prefix="rr" icon="glass-cheers"></VIcon>
      <span class="notification-badge" v-if="notificationCount > 0">
        <span>{{ notificationCount }}</span>
      </span>
    </router-link>

    <router-link :to="`/profiles/${user!.handle}`" role="button" class="nav-btn">
      <VUserAvatar v-if="user" :user="user" class="mx-auto"></VUserAvatar>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.nav-btn {
  display: block;
  padding: 10px 0;
  color: white;
  text-align: center;
  flex: 1;
}

.nav-btn > i.fi {
  font-size: 32px;
}

.nav-btn.router-link-active {
  color: white;
  pointer-events: none;
}

.notification-badge {
  position: absolute;
  font-weight: 1000;
  color: map-get($custom-colors, 'stone-900');
  background-color: map-get($custom-colors, 'amber-500');
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 50%;
  transform: translate(30px, -10px);
  border-radius: 100%;
  border: 4px solid map-get($custom-colors, 'stone-900');
}

.notification-badge > span {
  display: block;
}
</style>
