<script setup lang="ts">
import User from '@/models/User'
import { computed, type CSSProperties } from 'vue'
import VIcon from '@/components/VIcon.vue';
import type UserSummary from '@/models/UserSummary';

const props = withDefaults(defineProps<{ user: User | UserSummary; size?: number }>(), {
  size: 40
})

const style = computed(() => {
  let style: CSSProperties = {
    width: `${props.size}px`,
    height: `${props.size}px`
  }

  if (props.user.imageUrl) {
    style = {
      ...style,
      background: `url(${props.user.imageUrl})`,
      'background-size': 'cover',
      'background-position': 'center'
    }
  } else {
    style = {
      ...style,
      'font-size': `${props.size * .8}px`
    }
  }

  return style
})

</script>

<template>
  <div class="avatar shadow" :style="style">
    <VIcon v-if="!user.imageUrl" prefix="sr" icon="user" class="no-image"></VIcon>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: map-get($custom-colors, 'stone-400');
  border: 2px solid map-get($custom-colors, 'stone-200');
  overflow: hidden;
}

.no-image {
  display: block;
  pointer-events: none;
  color: map-get($custom-colors, 'stone-500');
  transform: translateY(8%);
}
</style>
